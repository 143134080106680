import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import store from './store';
import App from './App';
import './styles/common.css';
import PublicWorkshops from './pages/PublicWorkshops';

const theme: Theme = createTheme({
  window: {
    padding: 3,
  },
  sideMenu: {
    width: 150,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  palette: {
    primary: {
      main: 'rgb(40, 151, 213)',
    },
    secondary: {
      main: 'rgb(214, 11, 82)',
    },
    success: {
      main: 'rgb(159, 193, 31)',
    },
  },
});

const container = document.getElementById('root');
if (!container) {
  throw "No root container: can't init App";
}
const root = createRoot(container);

if (process.env.REACT_APP_MODE === 'public') {
  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <PublicWorkshops />
      </Provider>
    </ThemeProvider>,
  );
} else {
  root.render(
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CssBaseline />
        <App />
      </Provider>
    </ThemeProvider>,
  );
}
