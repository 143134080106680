import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import Typography from '@mui/material/Typography';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/MailOutline';
import { makeStyles } from 'tss-react/mui';
import { getError, isLoading } from '../features/globalSelectors';
import { load, setError, update } from '../features/globalSlice';
import GroupedSelectableList from '../components/GroupedSelectableList';
import {
  GroupedIdentifiedItems,
  IdentifiedItem,
  WorkshopRaw,
  WorkshopType,
  WorkshopTypesDetailsById,
} from '../@types/data';
import {
  getSelectedWorkshopType,
  getWorkshopTypesByFirstLetter,
  getWorkshopTypesDetails,
} from '../features/workshops/workshopsSelectors';
import { LinkType } from '../@types/enums';
import { selectWorkshopType } from '../features/workshops/workshopsSlice';
import LogoMissionLocale from '../images/logo_mission_locale.jpg';

const useStyles = makeStyles()((theme) => {
  const toolbarHeight = theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight as string, 10) : 0;

  return {
    root: {
      display: 'flex',
    },
    overlay: {
      color: 'black',
      zIndex: theme.zIndex.modal + 1,
    },
    toolbar: {
      padding: '0 !important',
    },
    buttons: {
      '& > button': {
        margin: theme.spacing(1),
      },
    },
    logoContainer: {
      flexWrap: 'nowrap',
    },
    logo: {
      height: theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight.toString(), 10) - 10 : 48,
      margin: '5px 10px',
      borderRadius: 3,
    },
    appName: {
      marginLeft: 10,
      fontWeight: 'bold',
      lineHeight: `${theme.mixins.toolbar.minHeight}px`,
    },
    appSubName: {
      marginLeft: 10,
      fontWeight: 'normal',
      lineHeight: `${theme.mixins.toolbar.minHeight}px`,
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(theme.window.padding),
    },
    subPanel: {
      height: '100%',
      flexWrap: 'nowrap',
      margin: '0 -20px',
    },
    listPanel: {
      height: `calc(100vh - ${toolbarHeight * 2}px)`,
      paddingBottom: '0 !important',
      overflow: 'auto',
    },
    disabledItem: {
      color: theme.palette.secondary.main,
    },
    listTitle: {
      padding: 10,
    },
  };
});

const sendMail = (workshopType: WorkshopType, workshop: WorkshopRaw) => {
  const email = process.env.REACT_APP_RECEIVER_EMAIL_ADDRESS ?? 'administratif.cej@mldesgraves.fr';
  const subject = `Inscription sur ateliers GJ`;

  const workshops: Array<string> = [];

  if (workshopType.links === LinkType.OR) {
    workshops.push(
      `${workshopType.name} - ${workshop.location?.name ?? 'Lieu indéfini'} du ${moment
        .unix(workshop.startDate)
        .format('DD/MM/YYYY HH:mm')} au ${moment.unix(workshop.endDate).format('DD/MM/YYYY HH:mm')}`,
    );
  } else {
    workshopType.workshops?.forEach((w) => {
      workshops.push(
        `${workshopType.name} - ${w.location?.name ?? 'Lieu indéfini'} du ${moment
          .unix(w.startDate)
          .format('DD/MM/YYYY HH:mm')} au ${moment.unix(w.endDate).format('DD/MM/YYYY HH:mm')}`,
      );
    });
  }

  const body = `Bonjour

Merci d'affecter <INDIQUER ICI LE NOM DU JEUNE> aux ateliers suivants :

  - ${workshops.join('\n  - ')}

Cordialement,
`;

  window.location.href = `mailto:${email}?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
};

const PublicWorkshops: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const error = useSelector(getError);
  const loading = useSelector(isLoading);

  const workshopTypesByFirstLetter: GroupedIdentifiedItems = useSelector(getWorkshopTypesByFirstLetter);
  const workshopTypesDetailsById: WorkshopTypesDetailsById = useSelector(getWorkshopTypesDetails);
  const selectedWorkshopType: WorkshopType = useSelector(getSelectedWorkshopType);

  const [lastTimeUpdated, setLastTimeUpdated] = useState<moment.Moment | null>(null);

  const onCloseError = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setError(null));
  }, []);

  const onWorkshopTypeClicked = useCallback((workshopType: IdentifiedItem) => {
    dispatch(selectWorkshopType(workshopType.id));
  }, []);

  const computeWorkshopTypeText = useCallback((workshopType: IdentifiedItem) => {
    return (workshopType as WorkshopType).name;
  }, []);

  const computeWorkshopTypeSubText = useCallback(
    (workshopType: IdentifiedItem) => {
      const workshopTypeDetails = workshopTypesDetailsById[workshopType.id];

      if (!workshopTypeDetails) {
        return 'Aucun atelier';
      }

      let remainingSeats = workshopTypeDetails.numSeats - workshopTypeDetails.numRegistrations;

      if (remainingSeats < 0) {
        remainingSeats = 0;
      }

      return `${remainingSeats} place${remainingSeats > 1 ? 's' : ''} restante${remainingSeats > 1 ? 's' : ''}`;
    },
    [workshopTypesDetailsById],
  );

  const computeDisabledWorkshopTypes = useCallback(
    (workshopType: IdentifiedItem) => {
      const workshopTypeDetails = workshopTypesDetailsById[workshopType.id];

      return workshopTypeDetails.numRegistrations >= workshopTypeDetails.numSeats;
    },
    [workshopTypesDetailsById],
  );

  useEffect(() => {
    dispatch(update('public-workshops'));
    setLastTimeUpdated(moment());

    const intervalId = setInterval(async () => {
      try {
        await dispatch(load('public-workshops'));
        setLastTimeUpdated(moment());
      } catch (err) {
        console.error('Error while auto refreshing data:', err);
      }
    }, 10000); // Refresh every 10 seconds
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.overlay} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={error !== null}
        onClose={onCloseError}
      >
        <Alert onClose={onCloseError} elevation={6} variant="filled" severity="error">
          Une erreur est survenue <em>({error})</em>
        </Alert>
      </Snackbar>

      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <Grid container className={classes.logoContainer}>
            <img src={LogoMissionLocale} alt="Logo MLG" className={classes.logo} />
            <Typography className={classes.appName} variant="h6" noWrap>
              ODSCo
            </Typography>
            <Typography className={classes.appSubName} variant="overline" noWrap>
              Offre De Services Collectif
            </Typography>
          </Grid>

          <Grid className={classes.buttons} container justifyContent="flex-end" wrap="nowrap">
            <Typography>
              Dernière mise à jour : {lastTimeUpdated ? lastTimeUpdated.format('DD MMMM YYYY HH:mm:ss') : '-'}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>

      <Container component="main" className={classes.content}>
        <Toolbar />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.listPanel}>
            <GroupedSelectableList
              groupedItems={workshopTypesByFirstLetter}
              selectedItem={selectedWorkshopType}
              onItemClick={onWorkshopTypeClicked}
              itemText={computeWorkshopTypeText}
              itemSubText={computeWorkshopTypeSubText}
              disabledItems={computeDisabledWorkshopTypes}
              order="ASC"
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.listPanel}>
            <Paper variant="outlined">
              <Typography variant="h5" className={classes.listTitle}>
                Liste des sessions{selectedWorkshopType ? ` - ${selectedWorkshopType.name}` : ''}
              </Typography>
              <List>
                {selectedWorkshopType?.workshops?.map((workshop) => (
                  <ListItem key={workshop.id}>
                    <ListItemText
                      primary={`${moment.unix(workshop.startDate).format('DD MMMM YYYY')} (${moment
                        .unix(workshop.startDate)
                        .format('HH:mm')} - ${moment.unix(workshop.endDate).format('HH:mm')}) à ${
                        workshop.location?.name ?? '-'
                      }`}
                      secondary={`${workshop.numYouthRegistered}/${workshop.numSeats}`}
                      className={workshop.numYouthRegistered >= workshop.numSeats ? classes.disabledItem : ''}
                    />
                    <ListItemSecondaryAction>
                      {selectedWorkshopType?.links === LinkType.AND && (
                        <Tooltip title="Le jeune doit être inscrit à toutes les sessions pour participer à cet atelier.">
                          <LinkIcon color="secondary" style={{ verticalAlign: 'middle' }} />
                        </Tooltip>
                      )}
                      <IconButton onClick={() => sendMail(selectedWorkshopType, workshop)} edge="end">
                        <Tooltip title="Envoyer un mail pour inscrire un jeune à cet atelier">
                          <EmailIcon />
                        </Tooltip>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default PublicWorkshops;
