import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import Dialog from '@mui/material/Dialog';
import { DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import { autoRegisterInstructors, autoRegisterYouth } from '../features/globalSlice';
import LabelledProgressBar from '../components/LabelledProgressBar';
import { isAdmin } from '../features/users/usersSelector';
import { generateAllYouthPlannings } from '../features/youth/youthSlice';
import { generateAllTimesheets } from '../features/workshops/workshopsSlice';
import { generateGlobalInstructorsPlanning } from '../features/instructors/instructorsSlice';

interface PageRoute {
  path: string;
  label: string;
  show?: boolean;
}

interface OperationRoute {
  onClick: () => void;
  label: string;
  show?: boolean;
}

interface SideMenuElements {
  lists: Array<PageRoute>;
  operations: Array<OperationRoute>;
  admin: Array<PageRoute>;
}

const useStyles = makeStyles()((theme) => ({
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexShrink: 0,
    width: theme.sideMenu.width,
    overflow: 'auto',
  },
  sideMenuElement: {
    width: theme.sideMenu.width,
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: theme.mixins.toolbar.minHeight,
  },
  divider: {
    margin: '30px 0 30px 0',
  },
  spacer: {
    flex: 1,
  },
  footer: {
    flex: 1,
    maxHeight: 20,
    marginLeft: 3,
  },
  footerText: {
    display: 'block',
    fontSize: '0.52rem',
    lineHeight: 1.2,
  },
}));

function renderPageRoutes(routes: Array<PageRoute>, currentPathName: string) {
  return routes.map((route, index) => {
    if (route.show !== undefined && !route.show) {
      return null;
    }

    return (
      <ListItemButton key={index} component={Link} to={route.path} selected={currentPathName.startsWith(route.path)}>
        <ListItemText>{route.label}</ListItemText>
      </ListItemButton>
    );
  });
}

function renderOperationRoutes(routes: Array<OperationRoute>) {
  return routes.map((route, index) => {
    if (route.show !== undefined && !route.show) {
      return null;
    }

    return (
      <ListItemButton key={index} onClick={route.onClick}>
        <ListItemText>{route.label}</ListItemText>
      </ListItemButton>
    );
  });
}

const SideMenu: React.FC = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const signedAsAdmin = useSelector(isAdmin);

  const [autoRegisterDialogOpened, setAutoRegisterDialogOpened] = useState(false);
  const [downloadDialogOpened, setDownloadDialogOpened] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const confirmAutoRegistration = useCallback(() => {
    setAutoRegisterDialogOpened(true);
  }, [setAutoRegisterDialogOpened]);

  const cancelAutoRegistration = useCallback(() => {
    setAutoRegisterDialogOpened(false);
  }, [setAutoRegisterDialogOpened]);

  const processYouthAutoRegistration = useCallback(async () => {
    await dispatch(autoRegisterYouth());
    setAutoRegisterDialogOpened(false);
  }, [setAutoRegisterDialogOpened]);

  const processInstructorsAutoRegistration = useCallback(async () => {
    await dispatch(autoRegisterInstructors());
    setAutoRegisterDialogOpened(false);
  }, [setAutoRegisterDialogOpened]);

  const confirmBulkDownload = useCallback(() => {
    setDownloadDialogOpened(true);
  }, [setDownloadDialogOpened]);

  const cancelBulkDownload = useCallback(() => {
    setDownloadDialogOpened(false);
    setDownloadProgress(0);
  }, [setDownloadDialogOpened]);

  const processYouthPlanningsDownload = useCallback(async () => {
    await dispatch(generateAllYouthPlannings(setDownloadProgress));
    cancelBulkDownload();
  }, [cancelBulkDownload, setDownloadProgress]);

  const processInstructorsPlanningDownload = useCallback(async () => {
    await dispatch(generateGlobalInstructorsPlanning(setDownloadProgress));
    cancelBulkDownload();
  }, [cancelBulkDownload, setDownloadProgress]);

  const processTimesheetsDownload = useCallback(async () => {
    await dispatch(generateAllTimesheets(setDownloadProgress));
    cancelBulkDownload();
  }, [cancelBulkDownload, setDownloadProgress]);

  const routes: SideMenuElements = useMemo(
    () => ({
      lists: [
        { path: '/app/youth', label: 'Jeunes' },
        { path: '/app/instructors', label: 'Intervenants' },
        { path: '/app/workshops', label: 'Ateliers' },
      ],
      operations: [
        {
          label: 'Affecter auto.',
          onClick: confirmAutoRegistration,
          show: signedAsAdmin,
        },
        {
          label: 'Tout télécharg.',
          onClick: confirmBulkDownload,
        },
      ],
      admin: [
        { path: '/app/admin', label: 'Gérer données' },
        { path: '/app/config', label: 'Paramètres' },
      ],
    }),
    [confirmAutoRegistration, confirmBulkDownload, signedAsAdmin],
  );

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      className={classes.sideMenu}
      classes={{
        paper: classes.sideMenuElement,
      }}
    >
      <List>
        <ListSubheader disableSticky>Listes</ListSubheader>
        {renderPageRoutes(routes.lists, pathname)}

        <Divider className={classes.divider} />

        <ListSubheader disableSticky>Opérations</ListSubheader>
        {renderOperationRoutes(routes.operations)}

        <Divider className={classes.divider} />

        <ListSubheader disableSticky>Administration</ListSubheader>
        {renderPageRoutes(routes.admin, pathname)}
      </List>

      <div className={classes.spacer} />

      <div className={classes.footer}>
        <Typography className={classes.footerText} variant="caption">
          © Mission Locale des Graves - {new Date().getFullYear()}
        </Typography>
      </div>

      <Dialog maxWidth="md" open={autoRegisterDialogOpened} onClose={cancelAutoRegistration}>
        <DialogTitle>Confirmation - Affectation automatique</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Qui voulez-vous affecter automatiquement ?
            <br />
            <br />
            Attention, l&apos;affectation automatique va réaffecter l&apos;ensemble des personnes possédant des voeux.
            Les inscriptions actuelles seront donc réinitialisée{' '}
            <em>(seulement les jeunes inscrits à un atelier de leur cohorte, dans le cas des jeunes)</em>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelAutoRegistration} color="primary">
            Annuler
          </Button>
          <Button onClick={processYouthAutoRegistration} color="primary" autoFocus>
            Jeunes
          </Button>
          <Button onClick={processInstructorsAutoRegistration} color="primary" autoFocus>
            Intervenants
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog maxWidth="md" open={downloadDialogOpened} onClose={cancelBulkDownload}>
        <DialogTitle>Confirmation - Téléchargement par lot</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Quels documents voulez-vous télécharger ?
            <br />
            <br />
            Tous les documents disponibles seront compressés dans une archive ZIP.
          </DialogContentText>
          <LabelledProgressBar value={downloadProgress} />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelBulkDownload} color="primary">
            Annuler
          </Button>
          <Button onClick={processTimesheetsDownload} color="primary" autoFocus>
            Feuilles de présences
          </Button>
          <Button onClick={processYouthPlanningsDownload} color="primary" autoFocus>
            Plannings des jeunes
          </Button>
          <Button onClick={processInstructorsPlanningDownload} color="primary" autoFocus>
            Planning des intervenants
          </Button>
        </DialogActions>
      </Dialog>
    </Drawer>
  );
};

export default SideMenu;
