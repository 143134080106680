import React, { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { loginUser } from '../features/users/usersSlice';
import PasswordInput from '../components/PasswordInput';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    backgroundImage: 'url(./assets/connection_bkg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [version, setVersion] = useState('0.0.0');

  useLayoutEffect(() => {
    const electron = (window as any).electron;
    if (!electron) {
      console.info('Not loading version as running in browser');
      return;
    }

    try {
      const appVersion = electron.version;
      setVersion(appVersion);
    } catch (err) {
      console.error('Error while getting app version', err);
    }
  }, []);

  const onLogin = useCallback(
    async (event: React.FormEvent) => {
      // Needed to avoid submitting form
      event.preventDefault();
      event.stopPropagation();

      await dispatch(loginUser(email, password));
      navigate(location.state?.from?.pathname || '/app', { replace: true });
    },
    [email, password],
  );

  const onEmailChanged = useCallback(
    (event) => {
      setEmail(event.target.value);
    },
    [setEmail],
  );

  const onPasswordChanged = useCallback(
    (event) => {
      setPassword(event.target.value);
    },
    [setPassword],
  );

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <form className={classes.form} noValidate onSubmit={onLogin}>
            <TextField
              label="Email"
              value={email}
              onChange={onEmailChanged}
              variant="outlined"
              margin="normal"
              fullWidth
              autoFocus
              required
              inputProps={{ autoComplete: 'username' }}
            />
            <PasswordInput
              label="Mot de passe"
              value={password}
              onChange={onPasswordChanged}
              formControlProps={{ fullWidth: true, variant: 'outlined' }}
              required
            />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Se connecter
            </Button>
            <Grid container>
              <Grid item xs>
                <Link component={RouterLink} to="/config" variant="body2">
                  Changer de serveur
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Typography variant="body2" color="textSecondary" align="center">
                Version {version}
              </Typography>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default SignIn;
