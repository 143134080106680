import { RootState } from '../../store/rootReducer';
import {
  IdentifiedItem,
  InstructorDetailed,
  InstructorsByFirstLetter,
  InstructorsFilters,
  LocationsIdsWishByPersonId,
  OrderableIdentifiedItems,
  TimeslotsById,
  WorkshopTypesWishRangeByPersonId,
} from '../../@types/data';
import { createSelector } from '@reduxjs/toolkit';
import { getSelectedWorkshop, getWorkshopsTimeslots, getWorkshopTypes } from '../workshops/workshopsSelectors';
import { getLocations } from '../locations/locationsSelectors';
import { checkTime } from '../../utils/utils';
import { RegistrationsFilterValue } from '../../@types/enums';

export const getInstructors = (state: RootState): Array<InstructorDetailed> => state.instructors.list;
export const getSelectedInstructorId = (state: RootState): number | null => state.instructors.selectedId;
export const getInstructorsFilters = (state: RootState): InstructorsFilters => state.instructors.filters;

export const getInstructorsFiltered = createSelector(
  getInstructors,
  getInstructorsFilters,
  (instructors, filters): Array<InstructorDetailed> => {
    // No filter apply
    if (filters.name.length === 0 && filters.registrations === RegistrationsFilterValue.ALL) {
      return instructors;
    }

    return instructors.filter((instructor) => {
      // Filter on name
      try {
        const nameFilter = RegExp(filters.name, 'i').test(`${instructor.lastname} ${instructor.firstname}`);
        if (!nameFilter) {
          return false;
        }
      } catch (err) {
        return false;
      }

      // Filter on registrations
      let registrationsFilter;
      switch (filters.registrations) {
        case RegistrationsFilterValue.NO_WISH:
          registrationsFilter = instructor.workshopTypesWishes.length === 0;
          break;

        case RegistrationsFilterValue.NO_REGISTRATION:
          registrationsFilter = instructor.registrations.length === 0;
          break;

        default:
          registrationsFilter = true;
          break;
      }

      return registrationsFilter;
    });
  },
);

export const getSelectedInstructor = createSelector(
  getInstructors,
  getSelectedInstructorId,
  (instructors, selectedInstructorId): InstructorDetailed | null => {
    return instructors.find((instructor) => instructor.id === selectedInstructorId) || null;
  },
);

export const getInstructorsByFirstLetter = createSelector(
  getInstructorsFiltered,
  (instructors): InstructorsByFirstLetter => {
    return instructors.reduce((result, instructor) => {
      const firstLetter = instructor.lastname.charAt(0).toUpperCase();

      if (!result[firstLetter]) {
        result[firstLetter] = [];
      }

      result[firstLetter].push(instructor);

      return result;
    }, {});
  },
);

export const getWorkshopTypesWishesForSelectedInstructor = createSelector(
  getSelectedInstructor,
  getWorkshopTypes,
  (instructor, workshopTypes): OrderableIdentifiedItems => {
    const result = {
      ranked: [] as Array<IdentifiedItem>,
      rejected: [] as Array<IdentifiedItem>,
    };

    if (!instructor) {
      return result;
    }

    result.ranked = [...instructor.workshopTypesWishes]
      .sort((a, b) => a.rank - b.rank)
      .map((wish) => wish.workshopType);

    const rankedIds = result.ranked.map((item) => item.id);
    result.rejected = workshopTypes
      .filter((workshopType) => !rankedIds.includes(workshopType.id))
      .sort((a, b) => a.name.localeCompare(b.name, 'fr'));

    return result;
  },
);

export const getLocationsWishesForSelectedInstructor = createSelector(
  getSelectedInstructor,
  getLocations,
  (instructor, locations): OrderableIdentifiedItems => {
    const result = {
      ranked: [] as Array<IdentifiedItem>,
      rejected: [] as Array<IdentifiedItem>,
    };

    if (!instructor) {
      return result;
    }

    result.ranked = [...instructor.locationsWishes].sort((a, b) => a.rank - b.rank).map((wish) => wish.location);

    const rankedIds = result.ranked.map((item) => item.id);
    result.rejected = locations
      .filter((location) => !rankedIds.includes(location.id))
      .sort((a, b) => a.name.localeCompare(b.name, 'fr'));

    return result;
  },
);

export const getInstructorsBusyTimeslots = createSelector(getInstructors, (instructors): TimeslotsById => {
  return instructors.reduce((result, instructor) => {
    const busyRegistrations = instructor.registrations.map((workshop) => ({
      startDate: workshop.startDate,
      endDate: workshop.endDate,
    }));

    const busyAvailabilities = instructor.availabilities.map((availability) => ({
      startDate: availability.startDate,
      endDate: availability.endDate,
    }));

    result[instructor.id] = busyRegistrations.concat(busyAvailabilities);

    return result;
  }, {} as TimeslotsById);
});

export const getAvailableInstructorsForSelectedWorkshop = createSelector(
  getSelectedWorkshop,
  getWorkshopsTimeslots,
  getInstructors,
  getInstructorsBusyTimeslots,
  (selectedWorkshop, workshopsTimeslots, instructors, instructorsBusyTimeslots): Array<InstructorDetailed> => {
    if (!selectedWorkshop) {
      return [];
    }

    return instructors.filter((instructor) => {
      return checkTime(instructorsBusyTimeslots[instructor.id], workshopsTimeslots[selectedWorkshop.id]);
    });
  },
);

export const getOrderedWorkshopTypesWishesByInstructorId = createSelector(getInstructors, (instructors) => {
  return instructors.reduce((result, instructor): WorkshopTypesWishRangeByPersonId => {
    result[instructor.id] = {
      min: 0,
      max: instructor.workshopTypesWishes.length,
      wishes: [...instructor.workshopTypesWishes].sort((a, b) => a.rank - b.rank).map((wish) => wish.workshopType),
    };
    return result;
  }, {});
});

export const getLocationsIdsWishesByInstructorId = createSelector(getInstructors, (instructors) => {
  return instructors.reduce((result, instructor): LocationsIdsWishByPersonId => {
    result[instructor.id] = [...instructor.locationsWishes].reduce((obj, wish) => {
      obj[wish.location.id] = wish.rank;
      return obj;
    }, {});
    return result;
  }, {});
});
