import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import {
  ColoredEvent,
  GroupedIdentifiedItems,
  IdentifiedItem,
  InstructorDetailed,
  InstructorsByFirstLetter,
  InstructorsFilters,
  Location,
  LocationWish,
  WorkshopType,
  WorkshopTypeWish,
} from '../@types/data';
import GroupedSelectableList from '../components/GroupedSelectableList';
import DetailsFields from '../components/DetailsFields';
import TabsLayout from '../components/TabsLayout';
import Calendar from '../components/Calendar';
import ReorderList from '../components/ReorderList';
import {
  getInstructorsByFirstLetter,
  getInstructorsFilters,
  getLocationsWishesForSelectedInstructor,
  getSelectedInstructor,
  getWorkshopTypesWishesForSelectedInstructor,
} from '../features/instructors/instructorsSelectors';
import {
  addAvailabilityToSelectedInstructor,
  generatePlanningsForSelectedInstructor,
  removeInstructorAvailability,
  resetInstructorsFilters,
  selectInstructor,
  setInstructorNameFilter,
  setInstructorRegistrationsFilter,
  setLocalInstructor,
  updateSelectedInstructorLocationWishes,
  updateSelectedInstructorWorkshopTypesWishes,
} from '../features/instructors/instructorsSlice';
import { setError } from '../features/globalSlice';
import AccordionFilters from '../containers/AccordionFilters';
import AvailabilitiesPanel from '../containers/AvailabilitiesPanel';
import { toFullName } from '../utils/utils';
import { RegistrationsFilterValue } from '../@types/enums';

const useStyles = makeStyles()((theme) => {
  const toolbarHeight = theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight as string, 10) : 0;

  return {
    root: {
      height: `calc(100vh - (${theme.spacing(theme.window.padding)} + ${toolbarHeight + 16}px))`,
    },
    panel: {
      height: '100%',
    },
    subPanel: {
      height: '100%',
      flexWrap: 'nowrap',
      margin: '0 -20px',
    },
    topSubSubPanel: {
      padding: '0 20px 5px 20px !important',
    },
    bottomSubSubPanel: {
      minHeight: 0,
      height: '100%',
      padding: '5px 20px 0 20px !important',
    },
    tabs: {
      height: '100%',
    },
    listPanel: {
      height: '100%',
      paddingBottom: '0 !important',
      overflow: 'auto',
    },
    filtersPanel: {
      paddingTop: '0 !important',
    },
    noneLabel: {
      textAlign: 'center',
    },
  };
});

const Instructors: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { instructorId } = useParams<any>();

  const [currentTab, setCurrentTab] = useState('0');
  const [events, setEvents] = useState<Array<ColoredEvent>>([]);

  const instructorsByFirstLetter: InstructorsByFirstLetter = useSelector(getInstructorsByFirstLetter);
  const selectedInstructor: InstructorDetailed | null = useSelector(getSelectedInstructor);
  const workshopTypesWishesForSelectedInstructor: GroupedIdentifiedItems = useSelector(
    getWorkshopTypesWishesForSelectedInstructor,
  );
  const locationsWishesForSelectedInstructor: GroupedIdentifiedItems = useSelector(
    getLocationsWishesForSelectedInstructor,
  );
  const instructorsFilters: InstructorsFilters = useSelector(getInstructorsFilters);

  useEffect(() => {
    if (instructorId !== undefined) {
      dispatch(selectInstructor(parseInt(instructorId, 10)));
    }
  }, [instructorId]);

  useEffect(() => {
    if (!selectedInstructor) {
      setCurrentTab('0');
    }
  }, [selectedInstructor]);

  useLayoutEffect(() => {
    if (!selectedInstructor) {
      setEvents([]);
      return;
    }

    const newEvents: Array<ColoredEvent> = selectedInstructor.registrations.map((workshop) => {
      return {
        title: `${workshop.type.name} - ${workshop.location?.name ?? 'Lieu indéfini'}`,
        start: moment.unix(workshop.startDate).toDate(),
        end: moment.unix(workshop.endDate).toDate(),
        colorIdText: workshop.type.name,
      };
    });
    setEvents(newEvents);
  }, [selectedInstructor]);

  const onWorkshopTypesWishesOrderChanged = useCallback(
    (rankedWishes: Array<IdentifiedItem>) => {
      if (!selectedInstructor) {
        return;
      }

      const instructor = {
        ...selectedInstructor,
        workshopTypesWishes: rankedWishes.map((wish) => ({ workshopType: wish })) as Array<WorkshopTypeWish>,
      };
      dispatch(setLocalInstructor(instructor));
      dispatch(updateSelectedInstructorWorkshopTypesWishes(rankedWishes as Array<WorkshopType>));
    },
    [selectedInstructor],
  );

  const onLocationsWishesOrderChanged = useCallback(
    (rankedWishes: Array<IdentifiedItem>) => {
      if (!selectedInstructor) {
        return;
      }

      const instructor = {
        ...selectedInstructor,
        locationsWishes: rankedWishes.map((wish) => ({ location: wish })) as Array<LocationWish>,
      };
      dispatch(setLocalInstructor(instructor));
      dispatch(updateSelectedInstructorLocationWishes(rankedWishes as Array<Location>));
    },
    [selectedInstructor],
  );

  const onInstructorClicked = useCallback((instructor: IdentifiedItem) => {
    dispatch(selectInstructor(instructor.id));
  }, []);

  const computeInstructorText = useCallback((instructor: IdentifiedItem) => {
    return `${(instructor as InstructorDetailed).lastname} ${(instructor as InstructorDetailed).firstname}`;
  }, []);

  const computeInstructorSubText = useCallback((instructor: IdentifiedItem) => {
    const numRegistrations = (instructor as InstructorDetailed).registrations.length;
    const numWishes = (instructor as InstructorDetailed).workshopTypesWishes.length;

    return (
      `${numWishes} voeu${numWishes > 1 ? 'x' : ''} - ` +
      `${numRegistrations} atelier${numRegistrations > 1 ? 's' : ''}`
    );
  }, []);

  const generatePlannings = useCallback(async () => {
    dispatch(generatePlanningsForSelectedInstructor());
  }, []);

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} md={4} className={classes.panel}>
        <Grid container direction="column" spacing={1} className={classes.subPanel}>
          <Grid item className={classes.filtersPanel}>
            <AccordionFilters
              filtersApplied={
                instructorsFilters.name !== '' || instructorsFilters.registrations !== RegistrationsFilterValue.ALL
              }
              onResetFilters={() => dispatch(resetInstructorsFilters())}
              textFilter={{
                name: 'Nom',
                value: instructorsFilters.name,
                onChange: (value) => dispatch(setInstructorNameFilter(value)),
              }}
              selectFilters={[
                {
                  name: 'Voeux/Inscriptions',
                  options: [
                    { label: 'Tous', value: RegistrationsFilterValue.ALL },
                    { label: 'Pas de voeu', value: RegistrationsFilterValue.NO_WISH },
                    { label: "Pas d'inscription", value: RegistrationsFilterValue.NO_REGISTRATION },
                  ],
                  value: instructorsFilters.registrations,
                  onChange: (value) => dispatch(setInstructorRegistrationsFilter(value as RegistrationsFilterValue)),
                },
              ]}
            />
          </Grid>
          <Grid item className={classes.listPanel}>
            <GroupedSelectableList
              groupedItems={instructorsByFirstLetter}
              selectedItem={selectedInstructor}
              onItemClick={onInstructorClicked}
              itemText={computeInstructorText}
              itemSubText={computeInstructorSubText}
              order="ASC"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} className={classes.panel}>
        <Grid container direction="column" spacing={5} className={classes.subPanel}>
          <Grid item className={classes.topSubSubPanel}>
            <DetailsFields
              spacing={1}
              item={selectedInstructor}
              fields={[
                {
                  label: 'Nom',
                  getter: (instructor: InstructorDetailed) => toFullName(instructor.firstname, instructor.lastname),
                  containerProps: { xs: 12 },
                },
              ]}
            />
          </Grid>

          <Grid item className={classes.bottomSubSubPanel}>
            <TabsLayout
              className={classes.tabs}
              tabs={[
                { name: 'Voeux', disabled: !selectedInstructor },
                { name: 'Lieux', disabled: !selectedInstructor },
                { name: 'Indisponibilités', disabled: !selectedInstructor },
                { name: 'Ateliers', disabled: !selectedInstructor },
                { name: 'Planning', disabled: !selectedInstructor },
              ]}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <ReorderList
                rankedItems={workshopTypesWishesForSelectedInstructor.ranked}
                rejectedItems={workshopTypesWishesForSelectedInstructor.rejected}
                onOrderChanged={onWorkshopTypesWishesOrderChanged}
              />

              <ReorderList
                rankedItems={locationsWishesForSelectedInstructor.ranked}
                rejectedItems={locationsWishesForSelectedInstructor.rejected}
                onOrderChanged={onLocationsWishesOrderChanged}
              />

              <AvailabilitiesPanel
                person={selectedInstructor}
                onAdd={(availability) => dispatch(addAvailabilityToSelectedInstructor(availability))}
                onRemove={(availabilityId: number) => dispatch(removeInstructorAvailability(availabilityId))}
                onError={(error: string) => dispatch(setError(error))}
              />

              <List>
                {selectedInstructor?.registrations.length === 0 && (
                  <Typography className={classes.noneLabel}>Aucun atelier</Typography>
                )}
                {selectedInstructor?.registrations.map((workshop) => (
                  <ListItemButton key={workshop.id} component={Link} to={`/app/workshops/${workshop.id}`}>
                    <ListItemText
                      primary={workshop.type.name}
                      secondary={`Du ${moment.unix(workshop.startDate).format('DD/MM/YYYY HH:mm')} au ${moment
                        .unix(workshop.endDate)
                        .format('DD/MM/YYYY HH:mm')} (${workshop.location?.name || 'Lieu inconnu'})`}
                    />
                  </ListItemButton>
                ))}
              </List>

              <React.Fragment>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={generatePlannings}>
                    Télécharger le planning
                  </Button>
                </Grid>
                <br />
                <Calendar events={events} disabledTimeslots={selectedInstructor?.availabilities} />
              </React.Fragment>
            </TabsLayout>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Instructors;
