import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Availability,
  Location,
  WorkshopType,
  YoungDetailed,
  YoungBase,
  YouthFilters,
  Instructor,
  Young,
} from '../../@types/data';
import { AppThunk } from '../../store';
import { LinkType, RegistrationsFilterValue } from '../../@types/enums';
import api from '../../services/api';
import { getSelectedYoung, getYouth } from './youthSelectors';
import { load, setError, setLoading, setSuccess } from '../globalSlice';
import { getSelectedWorkshop, getWorkshops } from '../workshops/workshopsSelectors';
import { checkTime } from '../../utils/utils';

interface YouthState {
  selectedId: number | null;
  list: Array<YoungDetailed>;
  filters: YouthFilters;
}

const initialFilters: YouthFilters = {
  name: '',
  registrations: RegistrationsFilterValue.ALL,
  group: 0, // 0 = all groups
  counselorId: 0, // 0 = all counselors
};

const initialState: YouthState = {
  selectedId: null,
  list: [],
  filters: initialFilters,
};

const youthSlice = createSlice({
  name: 'youth',
  initialState,
  reducers: {
    setYouthList(state, action: PayloadAction<Array<YoungDetailed>>) {
      state.list = action.payload;
    },
    selectYoung(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
    setLocalYoung(state, action: PayloadAction<YoungDetailed>) {
      const index = state.list.findIndex((young) => young.id === action.payload.id);
      state.list.splice(index, 1, action.payload);
    },
    setYoungNameFilter(state, action: PayloadAction<string>) {
      state.filters.name = action.payload;
    },
    setYoungRegistrationsFilter(state, action: PayloadAction<RegistrationsFilterValue>) {
      state.filters.registrations = action.payload;
    },
    setYoungGroupFilter(state, action: PayloadAction<number>) {
      state.filters.group = action.payload;
    },
    setYoungCounselorFilter(state, action: PayloadAction<number>) {
      state.filters.counselorId = action.payload;
    },
    resetYouthFilters(state) {
      state.filters = initialFilters;
    },
    updateCounselorsFromYouth(state, action: PayloadAction<Instructor>) {
      const updatedCounselor = action.payload;

      state.list = state.list.map((young) => {
        if (!young.counselor || young.counselor.id !== updatedCounselor.id) {
          return young;
        }

        return {
          ...young,
          counselor: updatedCounselor,
        };
      });
    },
    deleteCounselorsFromYouth(state, action: PayloadAction<Array<number>>) {
      const deletedCounselorsIds = action.payload;

      state.list = state.list.map((young) => {
        if (!young.counselor || !deletedCounselorsIds.includes(young.counselor.id)) {
          return young;
        }

        return {
          ...young,
          counselor: null,
        };
      });

      if (deletedCounselorsIds.includes(state.filters.counselorId)) {
        state.filters.counselorId = 0;
      }
    },
  },
});

export const {
  deleteCounselorsFromYouth,
  setYouthList,
  selectYoung,
  setLocalYoung,
  setYoungNameFilter,
  setYoungRegistrationsFilter,
  setYoungGroupFilter,
  setYoungCounselorFilter,
  resetYouthFilters,
  updateCounselorsFromYouth,
} = youthSlice.actions;

export default youthSlice.reducer;

export const syncYouth = (): AppThunk => async (dispatch) => {
  const youth: Array<YoungDetailed> = await api.getYouth();
  dispatch(setYouthList(youth));
};

export const createOrUpdateYoung =
  (young: YoungDetailed): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (young.id !== undefined) {
        await api.updateYoung(young);
      } else {
        await api.insertYouth([young]);
      }

      await dispatch(syncYouth());

      await dispatch(setSuccess('Jeune enregistré avec succès'));
    } catch (err: any) {
      console.error('Error while inserting or updating young:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const importMultipleYouth =
  (youth: Array<YoungBase>): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (youth.length === 0) {
        throw 'Aucun jeune à importer';
      }

      await api.insertYouth(youth);
      await dispatch(syncYouth());

      await dispatch(setSuccess('Jeunes importés avec succès'));
    } catch (err: any) {
      console.error('Error while importing youth', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const deleteYouth =
  (youthIds: Array<number>): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteYouthByIds(youthIds);
      await dispatch(syncYouth());

      await dispatch(setSuccess('Jeune(s) supprimé(s) avec succès'));
    } catch (err: any) {
      console.error('Error while deleting youth:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const updateSelectedYoungWorkshopTypesWishes =
  (workshopTypes: Array<WorkshopType>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedYoung = getSelectedYoung(getState());

      if (!selectedYoung) {
        dispatch(setLoading(false));
        return;
      }

      await api.updateYoungWorkshopTypesWishes(selectedYoung.id, workshopTypes);
      await dispatch(syncYouth());
    } catch (err: any) {
      console.error('Error while updating young workshop types wishes:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const updateSelectedYoungLocationWishes =
  (locations: Array<Location>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedYoung = getSelectedYoung(getState());

      if (!selectedYoung) {
        dispatch(setLoading(false));
        return;
      }

      await api.updateYoungLocationsWishes(selectedYoung.id, locations);
      await dispatch(syncYouth());
    } catch (err: any) {
      console.error('Error while updating young locations wishes:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const registerYoungToSelectedWorkshop =
  (young: YoungDetailed): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedWorkshop = getSelectedWorkshop(getState());

      if (!selectedWorkshop) {
        throw 'Aucun atelier sélectionné';
      }

      let newWorkshopsIds;
      switch (selectedWorkshop.type.links) {
        case LinkType.OR:
          if (selectedWorkshop.youthRegistered.length >= selectedWorkshop.numSeats) {
            throw "Plus de place disponible dans l'atelier";
          }

          newWorkshopsIds = young.registrations
            .filter((w) => w.type.id !== selectedWorkshop.type.id)
            .map((w) => w.id)
            .concat(selectedWorkshop.id);

          await api.updateYoungRegistrations(young.id, newWorkshopsIds);
          break;

        case LinkType.AND:
          const workshops = getWorkshops(getState());
          const linkedWorkshops = workshops.filter((workshop) => workshop.type.id === selectedWorkshop.type.id);

          // Check if any workshop is already full (if numbers of seats are different for instance)
          const someFull = linkedWorkshops.some((workshop) => workshop.youthRegistered.length >= workshop.numSeats);
          if (someFull) {
            throw 'Plus de place disponible dans un des ateliers liés';
          }

          newWorkshopsIds = young.registrations
            .filter((workshop) => workshop.type.id !== selectedWorkshop.type.id)
            .map((workshop) => workshop.id)
            .concat(linkedWorkshops.map((workshop) => workshop.id));

          await api.updateYoungRegistrations(young.id, newWorkshopsIds);
          break;

        default:
          throw `Type de lien d'atelier inconnu: ${selectedWorkshop.type.links}`;
      }

      await dispatch(load('youth-manual-registration'));
    } catch (err: any) {
      console.error('Error while adding young registration:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const unregisterYoungFromSelectedWorkshop =
  (young: Young): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedWorkshop = getSelectedWorkshop(getState());

      if (!selectedWorkshop) {
        dispatch(setLoading(false));
        return;
      }

      const fullYoung = getYouth(getState()).find((y) => y.id === young.id);
      if (!fullYoung) {
        throw 'Impossible de trouver le jeune à supprimer de la list';
      }

      let newWorkshopsIds;
      switch (selectedWorkshop.type.links) {
        case LinkType.OR:
          newWorkshopsIds = fullYoung.registrations
            .filter((workshop) => workshop.id !== selectedWorkshop.id)
            .map((workshop) => workshop.id);
          await api.updateYoungRegistrations(fullYoung.id, newWorkshopsIds);
          break;

        case LinkType.AND:
          newWorkshopsIds = fullYoung.registrations
            .filter((workshop) => workshop.type.id !== selectedWorkshop.type.id)
            .map((workshop) => workshop.id);
          await api.updateYoungRegistrations(fullYoung.id, newWorkshopsIds);
          break;

        default:
          throw `Type de lien d'atelier inconnu: ${selectedWorkshop.type.links}`;
      }

      await dispatch(load('youth-manual-registration'));
    } catch (err: any) {
      console.error('Error while removing young registration:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const addAvailabilityToSelectedYoung =
  (availability: Availability): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedYoung = getSelectedYoung(getState());

      if (!selectedYoung) {
        dispatch(setLoading(false));
        return;
      }

      if (!checkTime([availability], selectedYoung.registrations)) {
        throw 'Ce jeune est inscrit à un atelier ayant lieu pendant cette indisponibilité.';
      }

      availability.personId = selectedYoung.id;

      await api.addYoungAvailability(availability);

      await dispatch(syncYouth());
    } catch (err: any) {
      console.error('Error while adding availability to young:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const removeYoungAvailability =
  (availabilityId: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteYoungAvailability(availabilityId);
      await dispatch(syncYouth());
    } catch (err: any) {
      console.error('Error while removing availability from young:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const generatePlanningsForSelectedYoung = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const selectedYoung = getSelectedYoung(getState());

    if (!selectedYoung) {
      dispatch(setLoading(false));
      return;
    }

    await (window as any).electron.pdfGenerator.generatePlannings(selectedYoung);
  } catch (err: any) {
    console.error('Error while generating young plannings:', err);
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};

export const generateAllYouthPlannings =
  (progressCallback: (value: number) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await dispatch(syncYouth());
      const youth = getYouth(getState());
      if (youth.length > 0) {
        await (window as any).electron.pdfGenerator.generateAllYouthPlannings(youth, progressCallback);
      }
    } catch (err: any) {
      console.error('Error while generating all youth plannings:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };
