import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { syncYouth } from './youth/youthSlice';
import { syncWorkshops, syncWorkshopTypes } from './workshops/workshopsSlice';
import {
  getLocationsIdsWishesByYoungId,
  getOrderedWorkshopTypesWishesByYoungId,
  getYouth,
  getYouthBusyTimeslots,
} from './youth/youthSelectors';
import {
  getWorkshops,
  getConstrainedWorkshopsTimeslots,
  getWorkshopsRemainingSeatsById,
  getWorkshopsTimeslots,
  getWorkshopTypesById,
} from './workshops/workshopsSelectors';
import { AutoRegistrationData } from '../@types/data';
import { autoRegister, shuffleArray } from '../utils/utils';
import api from '../services/api';
import { syncLocations } from './locations/locationsSlice';
import { syncInstructors } from './instructors/instructorsSlice';
import { syncUsers } from './users/usersSlice';
import {
  getInstructors,
  getInstructorsBusyTimeslots,
  getLocationsIdsWishesByInstructorId,
  getOrderedWorkshopTypesWishesByInstructorId,
} from './instructors/instructorsSelectors';

interface GlobalState {
  error: string | null;
  success: string | null;
  loading: boolean;
}

const initialState: GlobalState = {
  error: null,
  success: null,
  loading: true,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSuccess(state, action: PayloadAction<string | null>) {
      state.success = action.payload;
    },
  },
});

export const { setError, setLoading, setSuccess } = globalSlice.actions;

export default globalSlice.reducer;

export type LoadableResources =
  | 'admin'
  | 'instructors'
  | 'instructors-auto-registration'
  | 'instructors-manual-registration'
  | 'public-workshops'
  | 'workshops'
  | 'youth'
  | 'youth-auto-registration'
  | 'youth-manual-registration';
export const load =
  (resource: LoadableResources, isAdmin = false): AppThunk =>
  async (dispatch) => {
    switch (resource) {
      case 'youth':
        await Promise.all([dispatch(syncWorkshopTypes()), dispatch(syncLocations()), dispatch(syncYouth())]);
        break;

      case 'youth-auto-registration':
        await Promise.all([
          dispatch(syncWorkshopTypes()),
          dispatch(syncLocations()),
          dispatch(syncWorkshops()),
          dispatch(syncYouth()),
        ]);
        break;

      case 'youth-manual-registration':
        await Promise.all([dispatch(syncWorkshops()), dispatch(syncYouth())]);
        break;

      case 'workshops':
        await Promise.all([
          dispatch(syncWorkshopTypes()),
          dispatch(syncLocations()),
          dispatch(syncWorkshops()),
          dispatch(syncInstructors()),
          dispatch(syncYouth()),
        ]);
        break;

      case 'public-workshops':
        await dispatch(syncWorkshopTypes(true));
        break;

      case 'instructors':
        await Promise.all([dispatch(syncWorkshopTypes()), dispatch(syncLocations()), dispatch(syncInstructors())]);
        break;

      case 'instructors-auto-registration':
        await Promise.all([
          dispatch(syncWorkshopTypes()),
          dispatch(syncLocations()),
          dispatch(syncWorkshops()),
          dispatch(syncInstructors()),
        ]);
        break;

      case 'instructors-manual-registration':
        await Promise.all([dispatch(syncWorkshops()), dispatch(syncInstructors())]);
        break;

      case 'admin':
        await Promise.all([
          dispatch(syncWorkshopTypes()),
          dispatch(syncLocations()),
          dispatch(syncInstructors()),
          dispatch(syncWorkshops()),
          dispatch(syncYouth()),
          isAdmin && dispatch(syncUsers()),
        ]);
        break;

      default:
        break;
    }
  };

export const update =
  (resource: LoadableResources, isAdmin = false): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await dispatch(load(resource, isAdmin));
    } catch (err: any) {
      console.error('Error while loading data:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const autoRegisterYouth = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    // ****** Unregister all youth from previous registrations (only if young group == workshop group)
    await api.clearYouthRegistrations(true);
    // ****** Refresh data to be sure they are up-to-date
    await dispatch(load('youth-auto-registration'));
    // ****** Prepare data : shuffle youth
    const youth = shuffleArray(getYouth(getState()), true);
    // ****** Prepare data
    const autoRegistrationData: AutoRegistrationData = {
      persons: {
        busyTimeslots: getYouthBusyTimeslots(getState()),
        list: youth,
      },
      wishes: {
        locations: getLocationsIdsWishesByYoungId(getState()),
        workshopTypes: getOrderedWorkshopTypesWishesByYoungId(getState()),
      },
      workshopTypes: getWorkshopTypesById(getState()),
      workshops: {
        remainingSeats: getWorkshopsRemainingSeatsById(getState()),
        timeslots: getConstrainedWorkshopsTimeslots(getState()),
      },
    };

    const localRegistrations = autoRegister(autoRegistrationData);
    await api.updateYouthRegistrations(localRegistrations);

    await dispatch(load('youth-auto-registration'));

    await dispatch(setSuccess('Affectation automatique des jeunes terminée avec succès'));
  } catch (err: any) {
    console.error('Error while auto registering youth to workshops:', err);
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};

export const autoRegisterInstructors = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    // ****** Refresh data to be sure they are up-to-date
    await dispatch(load('instructors-auto-registration'));
    // ****** Prepare data : shuffle instructors
    const instructors = shuffleArray(getInstructors(getState()), true);
    const workshops = getWorkshops(getState());
    // ****** Prepare data
    const autoRegistrationData: AutoRegistrationData = {
      persons: {
        busyTimeslots: getInstructorsBusyTimeslots(getState()),
        list: instructors,
      },
      wishes: {
        locations: getLocationsIdsWishesByInstructorId(getState()),
        workshopTypes: getOrderedWorkshopTypesWishesByInstructorId(getState()),
      },
      workshopTypes: getWorkshopTypesById(getState()),
      workshops: {
        remainingSeats: workshops.reduce((result, workshop) => {
          result[workshop.id] = 1; // 1 instructor per workshop
          return result;
        }, {}),
        timeslots: getWorkshopsTimeslots(getState()),
      },
    };

    const localRegistrations = autoRegister(autoRegistrationData, false);
    await api.updateInstructorsRegistrations(localRegistrations);

    await dispatch(load('instructors-auto-registration'));

    await dispatch(setSuccess('Affectation automatique des intervenants terminée avec succès'));
  } catch (err: any) {
    console.error('Error while auto registering instructors to workshops:', err);
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};
