import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location, LocationBase } from '../../@types/data';
import { AppThunk } from '../../store';
import api from '../../services/api';
import { setError, setLoading, setSuccess } from '../globalSlice';
import { deleteLocationsFromWorkshops } from '../workshops/workshopsSlice';
import { getCurrentUser } from '../users/usersSelector';
import { Role } from '../../@types/enums';

interface LocationsState {
  list: Array<Location>;
}

const initialState: LocationsState = {
  list: [],
};

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationsList(state, action: PayloadAction<Array<Location>>) {
      state.list = action.payload;
    },
  },
});

export const { setLocationsList } = locationsSlice.actions;

export default locationsSlice.reducer;

export const syncLocations = (): AppThunk => async (dispatch) => {
  const locations: Array<Location> = await api.getLocations();

  dispatch(setLocationsList(locations));
};

export const createOrUpdateLocation =
  (location: Location): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à créer ou modifier un lieu"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (location.id !== undefined) {
        await api.updateLocation(location);
      } else {
        await api.insertLocations([location]);
      }

      await dispatch(syncLocations());

      await dispatch(setSuccess('Lieu enregistrée avec succès'));
    } catch (err: any) {
      console.error('Error while inserting or updating locations:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const importMultipleLocations =
  (locations: Array<LocationBase>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à importer des lieux"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (locations.length === 0) {
        throw 'Aucun lieu à importer';
      }

      await api.insertLocations(locations);

      await dispatch(syncLocations());

      await dispatch(setSuccess('Lieux importés avec succès'));
    } catch (err: any) {
      console.error('Error while importing locations', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const deleteLocations =
  (locationsIds: Array<number>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à supprimer des lieux"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteLocationsByIds(locationsIds);

      await dispatch(syncLocations());
      await dispatch(deleteLocationsFromWorkshops(locationsIds));

      await dispatch(setSuccess('Lieu(x) supprimée(s) avec succès'));
    } catch (err: any) {
      console.error('Error while deleting locations:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };
