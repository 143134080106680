import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import {
  ColoredEvent,
  GroupedIdentifiedItems,
  IdentifiedItem,
  Instructor,
  Location,
  LocationWish,
  WorkshopType,
  WorkshopTypeWish,
  YoungDetailed,
  YouthByFirstLetter,
  YouthFilters,
} from '../@types/data';
import GroupedSelectableList from '../components/GroupedSelectableList';
import DetailsFields from '../components/DetailsFields';
import TabsLayout from '../components/TabsLayout';
import Calendar from '../components/Calendar';
import ReorderList from '../components/ReorderList';
import {
  getSelectedYoung,
  getLocationsWishesForSelectedYoung,
  getWorkshopTypesWishesForSelectedYoung,
  getYouthByFirstLetter,
  getYouthCounselors,
  getYouthFilters,
  getYouthGroups,
} from '../features/youth/youthSelectors';
import {
  addAvailabilityToSelectedYoung,
  generatePlanningsForSelectedYoung,
  removeYoungAvailability,
  resetYouthFilters,
  selectYoung,
  setLocalYoung,
  setYoungCounselorFilter,
  setYoungGroupFilter,
  setYoungNameFilter,
  setYoungRegistrationsFilter,
  updateSelectedYoungLocationWishes,
  updateSelectedYoungWorkshopTypesWishes,
} from '../features/youth/youthSlice';
import { setError } from '../features/globalSlice';
import AccordionFilters from '../containers/AccordionFilters';
import { RegistrationsFilterValue } from '../@types/enums';
import AvailabilitiesPanel from '../containers/AvailabilitiesPanel';
import { toFullName } from '../utils/utils';

const useStyles = makeStyles()((theme) => {
  const toolbarHeight = theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight as string, 10) : 0;

  return {
    root: {
      height: `calc(100vh - (${theme.spacing(theme.window.padding)} + ${toolbarHeight + 16}px))`,
    },
    panel: {
      height: '100%',
    },
    subPanel: {
      height: '100%',
      flexWrap: 'nowrap',
      margin: '0 -20px',
    },
    topSubSubPanel: {
      padding: '0 20px 5px 20px !important',
    },
    bottomSubSubPanel: {
      minHeight: 0,
      height: '100%',
      padding: '5px 20px 0 20px !important',
    },
    tabs: {
      height: '100%',
    },
    listPanel: {
      height: '100%',
      paddingBottom: '0 !important',
      overflow: 'auto',
    },
    filtersPanel: {
      paddingTop: '0 !important',
    },
    noneLabel: {
      textAlign: 'center',
    },
  };
});

const Youth: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { youngId } = useParams<any>();

  const [currentTab, setCurrentTab] = useState('0');
  const [events, setEvents] = useState<Array<ColoredEvent>>([]);

  const youthByFirstLetter: YouthByFirstLetter = useSelector(getYouthByFirstLetter);
  const selectedYoung: YoungDetailed | null = useSelector(getSelectedYoung);
  const workshopTypesWishesForSelectedYoung: GroupedIdentifiedItems = useSelector(
    getWorkshopTypesWishesForSelectedYoung,
  );
  const locationsWishesForSelectedYoung: GroupedIdentifiedItems = useSelector(getLocationsWishesForSelectedYoung);
  const youthFilters: YouthFilters = useSelector(getYouthFilters);
  const youthGroups: Array<number> = useSelector(getYouthGroups);
  const youthCounselors: Array<Instructor> = useSelector(getYouthCounselors);

  useEffect(() => {
    if (youngId !== undefined) {
      dispatch(selectYoung(parseInt(youngId, 10)));
    }
  }, [youngId]);

  useEffect(() => {
    if (!selectedYoung) {
      setCurrentTab('0');
    }
  }, [selectedYoung]);

  useLayoutEffect(() => {
    if (!selectedYoung) {
      setEvents([]);
      return;
    }

    const newEvents: Array<ColoredEvent> = selectedYoung.registrations.map((workshop) => {
      return {
        title: `${workshop.type.name} - ${workshop.location?.name ?? 'Lieu indéfini'}`,
        start: moment.unix(workshop.startDate).toDate(),
        end: moment.unix(workshop.endDate).toDate(),
        colorIdText: workshop.type.name,
      };
    });
    setEvents(newEvents);
  }, [selectedYoung]);

  const onWorkshopTypesWishesOrderChanged = useCallback(
    (rankedWishes: Array<IdentifiedItem>) => {
      if (!selectedYoung) {
        return;
      }

      const young = {
        ...selectedYoung,
        workshopTypesWishes: rankedWishes.map((wish) => ({ workshopType: wish })) as Array<WorkshopTypeWish>,
      };
      dispatch(setLocalYoung(young));
      dispatch(updateSelectedYoungWorkshopTypesWishes(rankedWishes as Array<WorkshopType>));
    },
    [selectedYoung],
  );

  const onLocationsWishesOrderChanged = useCallback(
    (rankedWishes: Array<IdentifiedItem>) => {
      if (!selectedYoung) {
        return;
      }

      const young = {
        ...selectedYoung,
        locationsWishes: rankedWishes.map((wish) => ({ location: wish })) as Array<LocationWish>,
      };
      dispatch(setLocalYoung(young));
      dispatch(updateSelectedYoungLocationWishes(rankedWishes as Array<Location>));
    },
    [selectedYoung],
  );

  const onYoungClicked = useCallback((young: IdentifiedItem) => {
    dispatch(selectYoung(young.id));
  }, []);

  const computeYoungText = useCallback((young: IdentifiedItem) => {
    return `${(young as YoungDetailed).lastname} ${(young as YoungDetailed).firstname}`;
  }, []);

  const computeYoungSubText = useCallback((young: IdentifiedItem) => {
    const numRegistrations = (young as YoungDetailed).registrations.length;
    const numWishes = (young as YoungDetailed).workshopTypesWishes?.length ?? 0;

    return (
      `${numWishes} voeu${numWishes > 1 ? 'x' : ''} - ` +
      `${numRegistrations} atelier${numRegistrations > 1 ? 's' : ''}`
    );
  }, []);

  const generatePlannings = useCallback(async () => {
    dispatch(generatePlanningsForSelectedYoung());
  }, []);

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} md={4} className={classes.panel}>
        <Grid container direction="column" spacing={1} className={classes.subPanel}>
          <Grid item className={classes.filtersPanel}>
            <AccordionFilters
              filtersApplied={
                youthFilters.name !== '' ||
                youthFilters.group > 0 ||
                youthFilters.counselorId !== 0 ||
                youthFilters.registrations != RegistrationsFilterValue.ALL
              }
              onResetFilters={() => dispatch(resetYouthFilters())}
              textFilter={{
                name: 'Nom',
                value: youthFilters.name,
                onChange: (value) => dispatch(setYoungNameFilter(value)),
              }}
              selectFilters={[
                {
                  name: 'Cohorte',
                  options: [
                    { label: 'Tous', value: 0 },
                    ...youthGroups.map((group) => ({ label: group.toString(10), value: group })),
                  ],
                  value: youthFilters.group,
                  onChange: (value) => dispatch(setYoungGroupFilter(value as number)),
                },
                {
                  name: 'Conseiller',
                  options: [
                    { label: 'Tous', value: 0 },
                    { label: 'Pas de conseiller', value: -1 },
                    ...youthCounselors.map((counselor) => ({
                      label: toFullName(counselor.firstname, counselor.lastname),
                      value: counselor.id,
                    })),
                  ],
                  value: youthFilters.counselorId,
                  onChange: (value) => dispatch(setYoungCounselorFilter(value as number)),
                },
                {
                  name: 'Voeux/Inscriptions',
                  options: [
                    { label: 'Tous', value: RegistrationsFilterValue.ALL },
                    { label: 'Pas de voeu', value: RegistrationsFilterValue.NO_WISH },
                    { label: "Pas d'inscription", value: RegistrationsFilterValue.NO_REGISTRATION },
                    { label: 'Insatisfaits', value: RegistrationsFilterValue.NOT_SATISFIED },
                  ],
                  value: youthFilters.registrations,
                  onChange: (value) => dispatch(setYoungRegistrationsFilter(value as RegistrationsFilterValue)),
                },
              ]}
            />
          </Grid>
          <Grid item className={classes.listPanel}>
            <GroupedSelectableList
              groupedItems={youthByFirstLetter}
              selectedItem={selectedYoung}
              onItemClick={onYoungClicked}
              itemText={computeYoungText}
              itemSubText={computeYoungSubText}
              order="ASC"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} className={classes.panel}>
        <Grid container direction="column" spacing={5} className={classes.subPanel}>
          <Grid item className={classes.topSubSubPanel}>
            <DetailsFields
              spacing={1}
              item={selectedYoung}
              fields={[
                {
                  label: 'Nom',
                  getter: (young: YoungDetailed) => toFullName(young.firstname, young.lastname),
                  containerProps: { xs: 12, sm: 4, md: 5 },
                },
                {
                  label: 'Cohorte',
                  getter: (young: YoungDetailed) => young.group.toString(),
                  containerProps: { xs: 12, sm: 4, md: 2 },
                },
                {
                  label: 'Conseiller/ère',
                  getter: (young: YoungDetailed) => toFullName(young.counselor?.firstname, young.counselor?.lastname),
                  containerProps: { xs: 12, sm: 4, md: 5 },
                },
              ]}
            />
          </Grid>

          <Grid item className={classes.bottomSubSubPanel}>
            <TabsLayout
              className={classes.tabs}
              tabs={[
                { name: 'Voeux', disabled: !selectedYoung },
                { name: 'Lieux', disabled: !selectedYoung },
                { name: 'Indisponibilités', disabled: !selectedYoung },
                { name: 'Ateliers', disabled: !selectedYoung },
                { name: 'Planning', disabled: !selectedYoung },
              ]}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <ReorderList
                rankedItems={workshopTypesWishesForSelectedYoung.ranked}
                rejectedItems={workshopTypesWishesForSelectedYoung.rejected}
                onOrderChanged={onWorkshopTypesWishesOrderChanged}
              />

              <ReorderList
                rankedItems={locationsWishesForSelectedYoung.ranked}
                rejectedItems={locationsWishesForSelectedYoung.rejected}
                onOrderChanged={onLocationsWishesOrderChanged}
              />

              <AvailabilitiesPanel
                person={selectedYoung}
                onAdd={(availability) => dispatch(addAvailabilityToSelectedYoung(availability))}
                onRemove={(availabilityId: number) => dispatch(removeYoungAvailability(availabilityId))}
                onError={(error: string) => dispatch(setError(error))}
              />

              <List>
                {selectedYoung?.registrations.length === 0 && (
                  <Typography className={classes.noneLabel}>Aucun atelier</Typography>
                )}
                {selectedYoung?.registrations.map((workshop) => (
                  <ListItemButton key={workshop.id} component={Link} to={`/app/workshops/${workshop.id}`}>
                    <ListItemText
                      primary={workshop.type.name}
                      secondary={`Du ${moment.unix(workshop.startDate).format('DD/MM/YYYY HH:mm')} au ${moment
                        .unix(workshop.endDate)
                        .format('DD/MM/YYYY HH:mm')} (${workshop.location?.name || 'Lieu inconnu'})`}
                    />
                  </ListItemButton>
                ))}
              </List>

              <React.Fragment>
                <Grid container justifyContent="flex-end">
                  <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={generatePlannings}>
                    Télécharger le planning
                  </Button>
                </Grid>
                <br />
                <Calendar events={events} disabledTimeslots={selectedYoung?.availabilities} />
              </React.Fragment>
            </TabsLayout>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Youth;
