import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducer';
import { Role } from '../../@types/enums';
import { User, UserAuth, UsersByFirstLetter } from '../../@types/data';

export const isSignedIn = (state: RootState): boolean =>
  state.users.currentUser !== null && state.users.currentUser.token !== '';
export const isAdmin = (state: RootState): boolean => state.users.currentUser?.role === Role.ADMINISTRATOR;
export const getCurrentUser = (state: RootState): UserAuth | null => state.users.currentUser;
export const getUsers = (state: RootState): Array<User> => state.users.list;

export const getUsersByFirstLetter = createSelector(getUsers, (users): UsersByFirstLetter => {
  return users.reduce((result, user) => {
    const firstLetter = user.email.charAt(0).toUpperCase();

    if (!result[firstLetter]) {
      result[firstLetter] = [];
    }

    result[firstLetter].push(user);

    return result;
  }, {});
});
