import axios, { Method } from 'axios';
import {
  Availability,
  InstructorDetailed,
  Instructor,
  LocalConfigSchema,
  Location,
  LocationBase,
  User,
  UserAuth,
  UserBase,
  Workshop,
  WorkshopDetailed,
  WorkshopsIdsByPersonId,
  WorkshopType,
  YoungDetailed,
  YoungBase,
} from '../@types/data';
import { getCurrentConfig } from '../features/config/configSelector';
import store from '../store';

// Force axios to indicate that we use JSON in all requests
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'HEAD, GET, POST, PUT, DELETE, OPTIONS';

async function request(method: Method, path: string, data?: any, params?: any): Promise<any> {
  try {
    const config: LocalConfigSchema = getCurrentConfig(store.getState());

    let url;
    if (config.server.address) {
      url = `${config.server.address}/${path}`;
    } else {
      url = `${process.env.REACT_APP_DEFAULT_SERVER_ADDRESS ?? 'api'}/${path}`;
    }

    const response = await axios.request({
      method: method,
      url,
      headers: {
        Authorization: `Bearer ${config.auth.token}`,
      },
      data,
      params,
    });
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    if (error.response) {
      switch (error.response.status) {
        case 401:
          throw 'Identifiants de connexion invalides. Essayez de vous reconnecter.';
        case 403:
          throw "Accès refusé. Vous n'avez pas les droits nécessaires.";
        case 404:
          throw "Serveur ou ressource introuvable. Vérifiez l'adresse du serveur.";
        case 422:
          throw "Les données envoyées n'ont pas le format attendu.";
        default:
          console.error('Server error', error);
          throw 'Erreur du serveur.';
      }
    } else if (error.request) {
      throw "Le serveur n'a pas répondu. Vérifiez l'adresse du serveur.";
    } else {
      console.error('Error', error);
      throw 'Erreur lors de la construction de la requête au serveur.';
    }
  }
}

export default {
  login: async (email: string, password: string): Promise<UserAuth> => {
    return request('POST', 'login', {
      email,
      password,
    });
  },
  getYouth: async (): Promise<Array<YoungDetailed>> => {
    return request('GET', 'youth');
  },
  getWorkshops: async (): Promise<Array<WorkshopDetailed>> => {
    return request('GET', 'workshops');
  },
  getWorkshopTypes: async (hasWorkshopOnly = false): Promise<Array<WorkshopType>> => {
    return request(
      'GET',
      'workshop-types',
      {},
      {
        hasWorkshopOnly: hasWorkshopOnly ? 1 : undefined,
      },
    );
  },
  getUsers: async (): Promise<Array<User>> => {
    return request('GET', 'users');
  },
  getLocations: async (): Promise<Array<Location>> => {
    return request('GET', 'locations');
  },
  getInstructors: async (): Promise<Array<InstructorDetailed>> => {
    return request('GET', 'instructors');
  },
  updateYoungWorkshopTypesWishes: async (youngId: number, workshopTypes: Array<WorkshopType>): Promise<void> => {
    return request('PUT', `youth/${youngId}/workshop-types-wishes`, {
      items: workshopTypes.map((workshopType) => workshopType.id),
    });
  },
  updateYoungLocationsWishes: async (youngId: number, locations: Array<Location>): Promise<void> => {
    return request('PUT', `youth/${youngId}/locations-wishes`, {
      items: locations.map((location) => location.id),
    });
  },
  addYoungAvailability: async (availability: Availability): Promise<void> => {
    const formattedAvailability = {
      startDate: availability.startDate,
      endDate: availability.endDate,
      reason: availability.reason,
      young_id: availability.personId,
    };
    return request('POST', `youth-availabilities`, {
      items: [formattedAvailability],
    });
  },
  updateYoungRegistrations: async (youngId: number, workshopsIds: Array<number>): Promise<void> => {
    return request('PUT', `youth/${youngId}/registrations`, {
      items: workshopsIds,
    });
  },
  updateYouthRegistrations: async (registrations: WorkshopsIdsByPersonId): Promise<void> => {
    return request('PUT', `youth/registrations`, registrations);
  },
  clearYouthRegistrations: async (byGroup: boolean): Promise<void> => {
    if (byGroup) {
      return request('DELETE', `youth-registrations-by-group`);
    } else {
      return request('DELETE', 'youth-registrations');
    }
  },
  updateInstructorWorkshopTypesWishes: async (
    instructorId: number,
    workshopTypes: Array<WorkshopType>,
  ): Promise<void> => {
    return request('PUT', `instructors/${instructorId}/workshop-types-wishes`, {
      items: workshopTypes.map((workshopType) => workshopType.id),
    });
  },
  updateInstructorLocationsWishes: async (instructorId: number, locations: Array<Location>): Promise<void> => {
    return request('PUT', `instructors/${instructorId}/locations-wishes`, {
      items: locations.map((location) => location.id),
    });
  },
  addInstructorAvailability: async (availability: Availability): Promise<void> => {
    const formattedAvailability = {
      startDate: availability.startDate,
      endDate: availability.endDate,
      reason: availability.reason,
      instructor_id: availability.personId,
    };
    return request('POST', `instructors-availabilities`, {
      items: [formattedAvailability],
    });
  },
  updateInstructorRegistrations: async (instructorId: number, workshopsIds: Array<number>): Promise<void> => {
    return request('PUT', `instructors/${instructorId}/registrations`, {
      items: workshopsIds,
    });
  },
  updateInstructorsRegistrations: async (registrations: WorkshopsIdsByPersonId): Promise<void> => {
    return request('PUT', `instructors/registrations`, registrations);
  },
  // clearInstructorsRegistrations: async (): Promise<void> => {
  //   return request('DELETE', 'instructors-registrations');
  // },
  insertWorkshopTypes: async (workshopTypes: Array<WorkshopType>): Promise<void> => {
    return request('POST', 'workshop-types', {
      items: workshopTypes,
    });
  },
  insertWorkshops: async (workshops: Array<Workshop>): Promise<void> => {
    const formattedWorkshops = workshops.map((workshop) => {
      return {
        startDate: workshop.startDate,
        endDate: workshop.endDate,
        location_id: workshop.location?.id,
        numSeats: workshop.numSeats,
        workshop_type_id: workshop.workshop_type_id,
        group: workshop.group,
        instructor_id: workshop.instructorsRegistered?.length ? workshop.instructorsRegistered[0].id : null,
      };
    });

    return request('POST', 'workshops', {
      items: formattedWorkshops,
    });
  },
  insertYouth: async (youth: Array<YoungBase>): Promise<void> => {
    const formattedYouth = youth.map((young) => {
      return {
        firstname: young.firstname,
        lastname: young.lastname,
        counselor_id: young.counselor?.id ?? null,
        group: young.group,
      };
    });

    return request('POST', 'youth', {
      items: formattedYouth,
    });
  },
  insertUsers: async (users: Array<UserBase>): Promise<void> => {
    const formattedUsers = users.map((user) => {
      return {
        email: user.email,
        password: user.password,
        role: user.role,
        instructor_id: user.instructor?.id,
      };
    });

    return request('POST', 'users', {
      items: formattedUsers,
    });
  },
  insertLocations: async (locations: Array<LocationBase>): Promise<void> => {
    return request('POST', 'locations', {
      items: locations,
    });
  },
  insertInstructors: async (instructors: Array<Instructor>): Promise<void> => {
    return request('POST', 'instructors', {
      items: instructors,
    });
  },
  updateWorkshopType: async (workshopType: WorkshopType): Promise<void> => {
    return request('PUT', `workshop-types/${workshopType.id}`, {
      item: workshopType,
    });
  },
  updateWorkshop: async (workshop: WorkshopDetailed): Promise<void> => {
    const formattedWorkshop = {
      startDate: workshop.startDate,
      endDate: workshop.endDate,
      location_id: workshop.location?.id,
      numSeats: workshop.numSeats,
      workshop_type_id: workshop.type.id,
      group: workshop.group,
      instructor_id: workshop.instructorsRegistered?.length ? workshop.instructorsRegistered[0].id : null,
    };

    return request('PUT', `workshops/${workshop.id}`, {
      item: formattedWorkshop,
    });
  },
  updateYoung: async (young: YoungDetailed): Promise<void> => {
    const formattedYoung = {
      firstname: young.firstname,
      lastname: young.lastname,
      counselor_id: young.counselor?.id,
      group: young.group,
    };

    return request('PUT', `youth/${young.id}`, {
      item: formattedYoung,
    });
  },
  updateUser: async (user: User): Promise<void> => {
    const formattedUser = {
      email: user.email,
      password: user.password,
      role: user.role,
      instructor_id: user.instructor?.id,
    };

    return request('PUT', `users/${user.id}`, {
      item: formattedUser,
    });
  },
  updateLocation: async (location: Location): Promise<void> => {
    return request('PUT', `locations/${location.id}`, {
      item: location,
    });
  },
  updateInstructor: async (instructor: Instructor): Promise<void> => {
    return request('PUT', `instructors/${instructor.id}`, {
      item: instructor,
    });
  },
  deleteWorkshopTypesByIds: async (workshopTypesIds: Array<number>): Promise<void> => {
    return request('DELETE', 'workshop-types', {
      items: workshopTypesIds,
    });
  },
  deleteWorkshopsByIds: async (workshopsIds: Array<number>): Promise<void> => {
    return request('DELETE', 'workshops', {
      items: workshopsIds,
    });
  },
  deleteYouthByIds: async (youthIds: Array<number>): Promise<void> => {
    return request('DELETE', 'youth', {
      items: youthIds,
    });
  },
  deleteUsersByIds: async (usersIds: Array<number>): Promise<void> => {
    return request('DELETE', 'users', {
      items: usersIds,
    });
  },
  deleteLocationsByIds: async (locationsIds: Array<number>): Promise<void> => {
    return request('DELETE', 'locations', {
      items: locationsIds,
    });
  },
  deleteInstructorsByIds: async (instructorsIds: Array<number>): Promise<void> => {
    return request('DELETE', 'instructors', {
      items: instructorsIds,
    });
  },
  deleteYoungAvailability: async (availabilityId: number): Promise<void> => {
    return request('DELETE', `youth-availabilities/${availabilityId}`);
  },
  deleteInstructorAvailability: async (availabilityId: number): Promise<void> => {
    return request('DELETE', `instructors-availabilities/${availabilityId}`);
  },
};
