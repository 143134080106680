import React from 'react';
import { NavigateAction, View } from 'react-big-calendar';
import moment from 'moment';

type ToolbarProps = {
  onNavigate: (newDate: Date, view: View, action: NavigateAction) => void;
  currentDate: Date;
};

class CalendarToolbar extends React.Component<ToolbarProps> {
  render(): JSX.Element {
    const { currentDate } = this.props;

    const firstDayOfWeekStr = moment(currentDate).startOf('week').format('DD MMMM YYYY');

    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={this.navigate.bind(this, 'TODAY')}>
            Aujourd&apos;hui
          </button>
          <button type="button" onClick={this.navigate.bind(this, 'PREV')}>
            Précédent
          </button>
          <button type="button" onClick={this.navigate.bind(this, 'NEXT')}>
            Suivant
          </button>
        </span>

        <span className="rbc-toolbar-label">Semaine du {firstDayOfWeekStr}</span>
      </div>
    );
  }

  private navigate(action: NavigateAction): void {
    this.props.onNavigate(new Date(), 'week', action);
  }
}

export default CalendarToolbar;
