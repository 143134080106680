import { combineReducers } from 'redux';
import configReducer from '../features/config/configSlice';
import globalReducer from '../features/globalSlice';
import instructorsReducer from '../features/instructors/instructorsSlice';
import locationsReducer from '../features/locations/locationsSlice';
import usersReducer from '../features/users/usersSlice';
import workshopsReducer from '../features/workshops/workshopsSlice';
import youthReducer from '../features/youth/youthSlice';

const rootReducer = combineReducers({
  config: configReducer,
  global: globalReducer,
  instructors: instructorsReducer,
  locations: locationsReducer,
  users: usersReducer,
  workshops: workshopsReducer,
  youth: youthReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
