import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from 'tss-react/mui';
import { IdentifiedItem } from '../@types/data';

const useStyles = makeStyles()((theme) => ({
  list: {
    margin: '10px 0 0 0',
    padding: 4,
  },
  item: {
    userSelect: 'none',
    padding: '4px 10px',
    margin: '0 0 10px 0',
    borderRadius: 4,
  },
  rejectedItem: {
    background: '#ebebeb',
    color: theme.palette.grey['800'],
  },
  rankedItem: {
    background: theme.palette.primary.main,
    color: 'white',
  },
  rankedHandle: {
    color: 'white',
  },
  separator: {
    borderTop: '1px dashed',
  },
  mainContent: {
    flex: 10,
  },
  secondaryContent: {
    fontStyle: 'italic',
  },
}));

type ReorderListProps = {
  rankedItems: Array<IdentifiedItem>;
  rejectedItems: Array<IdentifiedItem>;
  onOrderChanged: (rankedItems: Array<IdentifiedItem>, rejectedItems: Array<IdentifiedItem>) => void;
};

const ReorderList: React.FC<ReorderListProps> = (props: ReorderListProps) => {
  const { classes } = useStyles();
  const { rankedItems, rejectedItems, onOrderChanged } = props;

  const onDragEnd = useCallback(
    (result: DropResult): void => {
      if (!result.destination || result.reason !== 'DROP') {
        return;
      }

      const tmpRankedItems = Array.from(rankedItems);
      const tmpRejectedItems = Array.from(rejectedItems);

      if (result.destination.droppableId === 'droppableRanked') {
        if (result.source.droppableId === 'droppableRejected') {
          const [removed] = tmpRejectedItems.splice(result.source.index, 1);
          tmpRankedItems.splice(result.destination.index, 0, removed);
        } else {
          const [removed] = tmpRankedItems.splice(result.source.index, 1);
          tmpRankedItems.splice(result.destination.index, 0, removed);
        }
      } else if (result.destination.droppableId === 'droppableRejected') {
        if (result.source.droppableId === 'droppableRanked') {
          const [removed] = tmpRankedItems.splice(result.source.index, 1);
          tmpRejectedItems.splice(result.destination.index, 0, removed);
        } else {
          return;
        }
      }

      onOrderChanged(tmpRankedItems, tmpRejectedItems);
    },
    [rankedItems, rejectedItems],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <span>Souhaités</span>
      <Divider className={classes.separator} />

      <Droppable droppableId="droppableRanked">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={classes.list}>
            {rankedItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${classes.item} ${classes.rankedItem}`}
                    style={provided.draggableProps.style}
                  >
                    <ListItemIcon>
                      <DragHandleIcon className={classes.rankedHandle} />
                    </ListItemIcon>
                    <ListItemText primary={item.name || '-'} className={classes.mainContent} />
                    <ListItemText primary={index + 1} className={classes.secondaryContent} />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <span>Non souhaités</span>
      <Divider className={classes.separator} />

      <Droppable droppableId="droppableRejected">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className={classes.list}>
            {rejectedItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                {(provided) => (
                  <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`${classes.item} ${classes.rejectedItem}`}
                    style={provided.draggableProps.style}
                  >
                    <ListItemIcon>
                      <DragHandleIcon />
                    </ListItemIcon>
                    <ListItemText primary={item.name || '-'} className={classes.mainContent} />
                    <ListItemText primary="-" className={classes.secondaryContent} />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderList;
