import React, { useMemo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction/ListItemSecondaryAction';
import { makeStyles } from 'tss-react/mui';
import { IdentifiedItem } from '../@types/data';
import { IconWithTooltip } from '../@types/data';

const useStyles = makeStyles()((theme) => ({
  listItem: {
    wordWrap: 'break-word',
  },
  disabledItem: {
    color: theme.palette.secondary.main,
  },
}));

type SelectableListItemProps = {
  item: IdentifiedItem;
  itemText: ((item: IdentifiedItem) => string | JSX.Element) | string;
  selectedItem: IdentifiedItem | null;
  onItemClick: (item: IdentifiedItem) => void;
  itemSubText?: ((item: IdentifiedItem) => string | JSX.Element) | string;
  disabled?: boolean;
  multiSelectionEnabled?: boolean;
  multiSelected?: boolean;
  onMultiSelect?: (item: IdentifiedItem) => void;
  itemIcons?: ((item: IdentifiedItem) => Array<IconWithTooltip>) | Array<IconWithTooltip>;
};

const SelectableListItem: React.FC<SelectableListItemProps> = (props: SelectableListItemProps) => {
  const { classes } = useStyles();
  const {
    item,
    itemText,
    selectedItem,
    itemSubText,
    onItemClick,
    disabled,
    multiSelectionEnabled,
    multiSelected,
    onMultiSelect,
    itemIcons,
  } = props;

  const text = useMemo(() => {
    if (!itemText) {
      return '-';
    } else if (typeof itemText === 'function') {
      return itemText(item);
    } else {
      return itemText;
    }
  }, [itemText, item]);

  const subText = useMemo(() => {
    if (!itemSubText) {
      return '';
    } else if (typeof itemSubText === 'function') {
      return itemSubText(item);
    } else {
      return itemSubText;
    }
  }, [itemSubText, item]);

  const icons = useMemo(() => {
    if (!itemIcons) {
      return null;
    } else if (typeof itemIcons === 'function') {
      return itemIcons(item);
    } else {
      return itemIcons;
    }
  }, [itemIcons, item]);

  const selected = useMemo(() => {
    if (!selectedItem) {
      return false;
    }

    return item.id === selectedItem.id;
  }, [item, selectedItem]);

  return (
    <ListItemButton
      key={item.id}
      onClick={() => onItemClick(item)}
      selected={selected}
      className={classes.listItem}
      autoFocus={selected}
    >
      {multiSelectionEnabled && (
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={multiSelected}
            tabIndex={-1}
            disableRipple
            onChange={onMultiSelect ? () => onMultiSelect(item) : undefined}
          />
        </ListItemIcon>
      )}
      <ListItemText primary={text} className={disabled ? classes.disabledItem : ''} secondary={subText} />
      {icons && (
        <ListItemSecondaryAction>
          {icons.map((icon, index) => (
            <Tooltip key={index} title={icon.tooltip}>
              <IconButton edge="end" disableRipple>
                {icon.component}
              </IconButton>
            </Tooltip>
          ))}
        </ListItemSecondaryAction>
      )}
    </ListItemButton>
  );
};

export default SelectableListItem;
