import React, { useMemo } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import SelectableListItem from './SelectableListItem';
import { GroupedIdentifiedItems, IconWithTooltip, IdentifiedItem, IdentifiedItemsSelected } from '../@types/data';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    padding: 0,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

type GroupedSelectableListProps = {
  groupedItems: GroupedIdentifiedItems;
  selectedItem: IdentifiedItem | null;
  itemText: ((item: IdentifiedItem) => string | JSX.Element) | string;
  onItemClick: (item: IdentifiedItem) => void;
  groupText?: ((group: string) => string | JSX.Element) | string;
  itemSubText?: ((item: IdentifiedItem) => string | JSX.Element) | string;
  disabledItems?: (item: IdentifiedItem) => boolean;
  order?: string;
  multiSelection?: IdentifiedItemsSelected;
  onMultiSelect?: (item: IdentifiedItem) => void;
  itemIcons?: ((item: IdentifiedItem) => Array<IconWithTooltip>) | Array<IconWithTooltip>;
};

const GroupedSelectableList: React.FC<GroupedSelectableListProps> = (props: GroupedSelectableListProps) => {
  const { classes } = useStyles();
  const {
    groupedItems,
    selectedItem,
    itemText,
    onItemClick,
    groupText,
    itemSubText,
    disabledItems,
    order,
    multiSelection,
    onMultiSelect,
    itemIcons,
  } = props;

  const groupedItemsArray = useMemo(() => {
    const array = Object.entries(groupedItems);

    switch (order) {
      case 'ASC':
        return array.sort((a, b) => a[0].localeCompare(b[0], 'fr'));
      case 'DESC':
        return array.sort((a, b) => b[0].localeCompare(a[0], 'fr'));
      default:
        return array;
    }
  }, [groupedItems, order]);

  let groupTextFn;
  if (!groupText) {
    groupTextFn = (group: string) => group;
  } else if (typeof groupText !== 'function') {
    groupTextFn = () => groupText;
  } else {
    groupTextFn = groupText;
  }

  return (
    <Paper variant="outlined" className={classes.root}>
      <List subheader={<li />} className={classes.root}>
        {groupedItemsArray.map(([group, items], index) => (
          <React.Fragment key={group}>
            <li className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader>{groupTextFn(group)}</ListSubheader>
                {items.map((item) => (
                  <SelectableListItem
                    key={item.id}
                    item={item}
                    itemText={itemText}
                    selectedItem={selectedItem}
                    onItemClick={onItemClick}
                    itemSubText={itemSubText}
                    disabled={disabledItems ? disabledItems(item) : false}
                    multiSelectionEnabled={multiSelection !== undefined}
                    multiSelected={multiSelection ? multiSelection[item.id] ?? false : undefined}
                    onMultiSelect={onMultiSelect}
                    itemIcons={itemIcons}
                  />
                ))}
              </ul>
            </li>

            {index !== groupedItemsArray.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default GroupedSelectableList;
