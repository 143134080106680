export enum RegistrationsFilterValue {
  ALL,
  NO_WISH,
  NO_REGISTRATION,
  NOT_SATISFIED,
}

export enum LinkType {
  OR = 'OR',
  AND = 'AND',
}

export enum Role {
  COUNSELOR = 'COUNSELOR',
  ADMINISTRATOR = 'ADMINISTRATOR',
}
