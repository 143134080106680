import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Autocomplete, { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import PersonIcon from '@mui/icons-material/Person';
import SaveIcon from '@mui/icons-material/Save';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { makeStyles } from 'tss-react/mui';
import {
  IconWithTooltip,
  IdentifiedItem,
  Instructor,
  InstructorDetailed,
  Location,
  WorkshopDetailed,
  WorkshopsByFirstLetter,
  WorkshopsFilters,
  WorkshopType,
  Young,
  YoungDetailed,
} from '../@types/data';
import GroupedSelectableList from '../components/GroupedSelectableList';
import DetailsFields from '../components/DetailsFields';
import TabsLayout from '../components/TabsLayout';
import {
  generateTimesheetForSelectedWorkshop,
  resetWorkshopsFilters,
  selectWorkshop,
  setWorkshopGroupFilter,
  setWorkshopLocationFilter,
  setWorkshopNameFilter,
  setWorkshopYouthRegistrationsFilter,
  setWorkshopTypeFilter,
  setWorkshopInstructorFilter,
} from '../features/workshops/workshopsSlice';
import {
  getSelectedWorkshop,
  getWorkshopsByFirstLetter,
  getWorkshopsFilters,
  getWorkshopsGroups,
  getWorkshopTypes,
} from '../features/workshops/workshopsSelectors';
import { getAvailableYouthForSelectedWorkshop } from '../features/youth/youthSelectors';
import AccordionFilters from '../containers/AccordionFilters';
import { RegistrationsFilterValue } from '../@types/enums';
import { registerYoungToSelectedWorkshop, unregisterYoungFromSelectedWorkshop } from '../features/youth/youthSlice';
import { getLocations } from '../features/locations/locationsSelectors';
import { toFullName } from '../utils/utils';
import {
  getAvailableInstructorsForSelectedWorkshop,
  getInstructors,
} from '../features/instructors/instructorsSelectors';
import {
  registerInstructorToSelectedWorkshop,
  unregisterInstructorFromSelectedWorkshop,
} from '../features/instructors/instructorsSlice';

const useStyles = makeStyles()((theme) => {
  const toolbarHeight = theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight as string, 10) : 0;

  return {
    root: {
      height: `calc(100vh - (${theme.spacing(theme.window.padding)} + ${toolbarHeight + 16}px))`,
    },
    panel: {
      height: '100%',
    },
    subPanel: {
      height: '100%',
      flexWrap: 'nowrap',
      margin: '0 -20px',
    },
    topSubSubPanel: {
      padding: '0 20px 5px 20px !important',
    },
    bottomSubSubPanel: {
      minHeight: 0,
      padding: '5px 20px 0 20px !important',
      height: '100%',
    },
    autocompleteInputContainer: {
      width: '100%',
    },
    autocompleteListItem: {
      display: 'flex',
      width: '100%',
    },
    spacer: {
      flex: 1,
    },
    tabs: {
      height: '100%',
    },
    listPanel: {
      height: '100%',
      paddingBottom: '0 !important',
      overflow: 'auto',
    },
    filtersPanel: {
      paddingTop: '0 !important',
    },
  };
});

const Workshops: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { workshopId } = useParams<any>();

  const autocompleteYoungInputRef = React.createRef<HTMLInputElement>();
  const autocompleteInstructorInputRef = React.createRef<HTMLInputElement>();

  const [currentTab, setCurrentTab] = useState('0');

  const [newYoungName, setNewYoungName] = useState('');
  const [youngToAdd, setYoungToAdd] = useState(null);
  const [clearYoungName, setClearYoungName] = useState(false);

  const [newInstructorName, setNewInstructorName] = useState('');
  const [instructorToAdd, setInstructorToAdd] = useState(null);
  const [clearInstructorName, setClearInstructorName] = useState(false);

  const workshopsByFirstLetter: WorkshopsByFirstLetter = useSelector(getWorkshopsByFirstLetter);
  const selectedWorkshop: WorkshopDetailed = useSelector(getSelectedWorkshop);
  const availableYouthForSelectedWorkshop: Array<YoungDetailed> = useSelector(getAvailableYouthForSelectedWorkshop);
  const availableInstructorsForSelectedWorkshop: Array<YoungDetailed> = useSelector(
    getAvailableInstructorsForSelectedWorkshop,
  );
  const workshopsFilters: WorkshopsFilters = useSelector(getWorkshopsFilters);
  const workshopTypes: Array<WorkshopType> = useSelector(getWorkshopTypes);
  const locations: Array<Location> = useSelector(getLocations);
  const instructors: Array<InstructorDetailed> = useSelector(getInstructors);
  const workshopsGroups: Array<number> = useSelector(getWorkshopsGroups);

  useEffect(() => {
    if (workshopId !== undefined) {
      dispatch(selectWorkshop(parseInt(workshopId, 10)));
    }
  }, [workshopId]);

  useLayoutEffect(() => {
    if (currentTab === '0') {
      autocompleteYoungInputRef.current?.focus();
    } else {
      autocompleteInstructorInputRef.current?.focus();
    }
  }, [currentTab, selectedWorkshop]);

  useEffect(() => {
    if (clearYoungName) {
      setNewYoungName('');
      setYoungToAdd(null);
      setClearYoungName(false);
    }
  }, [clearYoungName]);

  useEffect(() => {
    if (clearInstructorName) {
      setNewInstructorName('');
      setInstructorToAdd(null);
      setClearInstructorName(false);
    }
  }, [clearInstructorName]);

  const onYoungAdded = useCallback(
    (event: React.ChangeEvent<unknown>, value: YoungDetailed | null, reason: AutocompleteChangeReason) => {
      if (reason !== 'selectOption') return;
      if (!value) return;

      setClearYoungName(true);

      dispatch(registerYoungToSelectedWorkshop(value));
    },
    [],
  );

  const onInstructorAdded = useCallback(
    (event: React.ChangeEvent<unknown>, value: InstructorDetailed | null, reason: AutocompleteChangeReason) => {
      if (reason !== 'selectOption') return;
      if (!value) return;

      setClearInstructorName(true);

      dispatch(registerInstructorToSelectedWorkshop(value));
    },
    [],
  );

  const removeYoungFromSelectedWorkshop = useCallback((young: Young) => {
    dispatch(unregisterYoungFromSelectedWorkshop(young));
  }, []);

  const removeInstructorFromSelectedWorkshop = useCallback((instructor: Instructor) => {
    dispatch(unregisterInstructorFromSelectedWorkshop(instructor));
  }, []);

  const onWorkshopClicked = useCallback((workshop: IdentifiedItem) => {
    dispatch(selectWorkshop(workshop.id));
  }, []);

  const computeWorkshopText = useCallback((workshop: IdentifiedItem) => {
    return (workshop as WorkshopDetailed).type.name;
  }, []);

  const computeWorkshopSubText = useCallback((workshop: IdentifiedItem) => {
    const workshopDetailed = workshop as WorkshopDetailed;

    const numSeats = workshopDetailed.numSeats;
    const numRegistrations = workshopDetailed.youthRegistered.length;

    return `${numRegistrations}/${numSeats}`;
  }, []);

  const computeWorkshopIcons = useCallback((workshop: IdentifiedItem) => {
    const workshopDetailed = workshop as WorkshopDetailed;

    const icons: Array<IconWithTooltip> = [];
    if (workshopDetailed.instructorsRegistered.length === 0) {
      icons.push({
        tooltip: "Aucun intervenant n'anime cet atelier",
        component: <PersonIcon fontSize="small" color="secondary" />,
      });
    }

    if (!workshopDetailed.location?.id) {
      icons.push({
        tooltip: "Aucun lieu n'accueille cet atelier",
        component: <LocationOnIcon fontSize="small" color="secondary" />,
      });
    }

    return icons;
  }, []);

  const computeDisabledWorkshops = useCallback((workshop: IdentifiedItem) => {
    const numSeats = (workshop as WorkshopDetailed).numSeats;
    const numRegistrations = (workshop as WorkshopDetailed).youthRegistered.length;

    return numRegistrations >= numSeats;
  }, []);

  const generateTimesheet = useCallback(async () => {
    dispatch(generateTimesheetForSelectedWorkshop());
  }, []);

  const getYouthWishRankText = useCallback(
    (young: Young) => {
      const youngWish = selectedWorkshop.type.youthWishes?.find((wish) => wish.youngId === young.id);

      if (!youngWish) {
        return '';
      }

      return `Voeu n°${youngWish.rank}`;
    },
    [selectedWorkshop],
  );

  const getInstructorsWishRankText = useCallback(
    (instructor: Instructor) => {
      const instructorWish = selectedWorkshop.type.instructorsWishes?.find(
        (wish) => wish.instructorId === instructor.id,
      );

      if (!instructorWish) {
        return '';
      }

      return `Voeu n°${instructorWish.rank}`;
    },
    [selectedWorkshop],
  );

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} md={4} className={classes.panel}>
        <Grid container direction="column" spacing={1} className={classes.subPanel}>
          <Grid item className={classes.filtersPanel}>
            <AccordionFilters
              filtersApplied={
                workshopsFilters.name !== '' ||
                workshopsFilters.typeId !== 0 ||
                workshopsFilters.locationId !== 0 ||
                workshopsFilters.group > 0 ||
                workshopsFilters.youthRegistrations !== RegistrationsFilterValue.ALL ||
                workshopsFilters.instructorId !== 0
              }
              onResetFilters={() => dispatch(resetWorkshopsFilters())}
              textFilter={{
                name: 'Nom',
                value: workshopsFilters.name,
                onChange: (value) => dispatch(setWorkshopNameFilter(value)),
              }}
              selectFilters={[
                {
                  name: 'Type',
                  options: [
                    { label: 'Tous', value: 0 },
                    ...workshopTypes.map((workshopType) => ({ label: workshopType.name, value: workshopType.id })),
                  ],
                  value: workshopsFilters.typeId,
                  onChange: (value) => dispatch(setWorkshopTypeFilter(value as number)),
                },
                {
                  name: 'Lieu',
                  options: [
                    { label: 'Tous', value: 0 },
                    { label: 'Lieu inconnu', value: -1 },
                    ...locations.map((location) => ({ label: location.name, value: location.id })),
                  ],
                  value: workshopsFilters.locationId,
                  onChange: (value) => dispatch(setWorkshopLocationFilter(value as number)),
                },
                {
                  name: 'Cohorte',
                  options: [
                    { label: 'Tous', value: 0 },
                    ...workshopsGroups.map((group) => ({ label: group.toString(10), value: group })),
                  ],
                  value: workshopsFilters.group,
                  onChange: (value) => dispatch(setWorkshopGroupFilter(value as number)),
                },
                {
                  name: 'Intervenant',
                  options: [
                    { label: 'Tous', value: 0 },
                    { label: "Pas d'intervenant", value: -1 },
                    ...instructors.map((instructor) => ({
                      label: toFullName(instructor.firstname, instructor.lastname),
                      value: instructor.id,
                    })),
                  ],
                  value: workshopsFilters.instructorId,
                  onChange: (value) => dispatch(setWorkshopInstructorFilter(value as number)),
                },
                {
                  name: 'Inscriptions',
                  options: [
                    { label: 'Tous', value: RegistrationsFilterValue.ALL },
                    { label: 'Pas de voeu', value: RegistrationsFilterValue.NO_WISH },
                    { label: "Pas d'inscription", value: RegistrationsFilterValue.NO_REGISTRATION },
                    { label: 'Pas rempli', value: RegistrationsFilterValue.NOT_SATISFIED },
                  ],
                  value: workshopsFilters.youthRegistrations,
                  onChange: (value) => dispatch(setWorkshopYouthRegistrationsFilter(value as RegistrationsFilterValue)),
                },
              ]}
            />
          </Grid>
          <Grid item className={classes.listPanel}>
            <GroupedSelectableList
              groupedItems={workshopsByFirstLetter}
              selectedItem={selectedWorkshop}
              onItemClick={onWorkshopClicked}
              itemText={computeWorkshopText}
              itemSubText={computeWorkshopSubText}
              disabledItems={computeDisabledWorkshops}
              itemIcons={computeWorkshopIcons}
              order="ASC"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} className={classes.panel}>
        <Grid container direction="column" spacing={5} className={classes.subPanel}>
          <Grid item className={classes.topSubSubPanel}>
            <DetailsFields
              spacing={1}
              item={selectedWorkshop}
              fields={[
                {
                  label: 'Cohorte',
                  getter: (workshop: WorkshopDetailed) => workshop.group.toString(),
                  containerProps: { xs: 12, sm: 4 },
                },
                {
                  label: 'Date début',
                  getter: (workshop: WorkshopDetailed) => moment.unix(workshop.startDate).format('DD/MM/YYYY HH:mm'),
                  containerProps: { xs: 12, sm: 4 },
                },
                {
                  label: 'Date fin',
                  getter: (workshop: WorkshopDetailed) => moment.unix(workshop.endDate).format('DD/MM/YYYY HH:mm'),
                  containerProps: { xs: 12, sm: 4 },
                },
                {
                  label: 'Lieu',
                  getter: (workshop: WorkshopDetailed) => workshop.location?.name || '',
                  containerProps: { xs: 12, sm: 6 },
                },
                {
                  label: 'Intervenant/e',
                  getter: (workshop: WorkshopDetailed) =>
                    workshop.instructorsRegistered
                      .map((instructor) => toFullName(instructor.firstname, instructor.lastname))
                      .join(', '),
                  containerProps: { xs: 12, sm: 6 },
                },
              ]}
            />
          </Grid>

          <Grid item className={classes.bottomSubSubPanel}>
            <TabsLayout
              className={classes.tabs}
              tabs={[{ name: 'Jeunes' }, { name: 'Intervenant' }]}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
            >
              <React.Fragment>
                {selectedWorkshop && (
                  <Grid container justifyContent="flex-end">
                    <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={generateTimesheet}>
                      Télécharger la feuille de présence
                    </Button>
                  </Grid>
                )}
                <List>
                  {selectedWorkshop &&
                    selectedWorkshop.youthRegistered.map((young) => (
                      <ListItem
                        key={young.id}
                        secondaryAction={
                          <IconButton edge="end" onClick={() => removeYoungFromSelectedWorkshop(young)}>
                            <ClearIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton component={Link} to={`/app/youth/${young.id}`}>
                          <ListItemText
                            primary={`${young.lastname} ${young.firstname}`}
                            secondary={getYouthWishRankText(young)}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>

                {selectedWorkshop && selectedWorkshop.youthRegistered.length < selectedWorkshop.numSeats && (
                  <Autocomplete
                    id="combo-box-demo"
                    options={availableYouthForSelectedWorkshop}
                    getOptionLabel={(option: YoungDetailed) => `${option.lastname} ${option.firstname}`}
                    className={classes.autocompleteInputContainer}
                    blurOnSelect
                    onChange={onYoungAdded}
                    value={youngToAdd}
                    onInputChange={(_, value) => setNewYoungName(value)}
                    inputValue={newYoungName}
                    noOptionsText="Aucun jeune disponible"
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div className={classes.autocompleteListItem}>
                          {option.lastname} {option.firstname}
                          <div className={classes.spacer} />
                          <span>{getYouthWishRankText(option)}</span>
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={autocompleteYoungInputRef}
                        label="Ajouter un jeune"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </React.Fragment>

              <React.Fragment>
                <List>
                  {selectedWorkshop &&
                    selectedWorkshop.instructorsRegistered.map((instructor) => (
                      <ListItem
                        key={instructor.id}
                        secondaryAction={
                          <IconButton edge="end" onClick={() => removeInstructorFromSelectedWorkshop(instructor)}>
                            <ClearIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton component={Link} to={`/app/instructors/${instructor.id}`}>
                          <ListItemText
                            primary={`${instructor.lastname} ${instructor.firstname}`}
                            secondary={getInstructorsWishRankText(instructor)}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>

                {selectedWorkshop && selectedWorkshop.instructorsRegistered.length === 0 && (
                  <Autocomplete
                    id="combo-box-demo"
                    options={availableInstructorsForSelectedWorkshop}
                    getOptionLabel={(option: InstructorDetailed) => `${option.lastname} ${option.firstname}`}
                    className={classes.autocompleteInputContainer}
                    blurOnSelect
                    onChange={onInstructorAdded}
                    value={instructorToAdd}
                    onInputChange={(_, value) => setNewInstructorName(value)}
                    inputValue={newInstructorName}
                    noOptionsText="Aucun intervenant disponible"
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div className={classes.autocompleteListItem}>
                          {option.lastname} {option.firstname}
                          <div className={classes.spacer} />
                          <span>{getInstructorsWishRankText(option)}</span>
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={autocompleteInstructorInputRef}
                        label="Ajouter un intervenant"
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </React.Fragment>
            </TabsLayout>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Workshops;
