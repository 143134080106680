import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkshopDetailed, Workshop, WorkshopsFilters, WorkshopType } from '../../@types/data';
import { AppThunk } from '../../store';
import { RegistrationsFilterValue, Role } from '../../@types/enums';
import api from '../../services/api';
import { getSelectedWorkshop, getWorkshops } from './workshopsSelectors';
import { setError, setLoading, setSuccess } from '../globalSlice';
import { getCurrentUser } from '../users/usersSelector';

interface WorkshopsState {
  selectedId: number | null;
  selectedTypeId: number | null;
  list: Array<WorkshopDetailed>;
  types: Array<WorkshopType>;
  filters: WorkshopsFilters;
}

const initialFilters: WorkshopsFilters = {
  name: '',
  youthRegistrations: RegistrationsFilterValue.ALL,
  instructorId: 0,
  group: 0, // 0 = all groups
  typeId: 0, // 0 = all types
  locationId: 0, // 0 = all locations
};

const initialState: WorkshopsState = {
  selectedId: null,
  selectedTypeId: null,
  list: [],
  types: [],
  filters: initialFilters,
};

const workshopsSlice = createSlice({
  name: 'workshops',
  initialState,
  reducers: {
    setWorkshopsList(state, action: PayloadAction<Array<WorkshopDetailed>>) {
      state.list = action.payload;
    },
    setWorkshopTypes(state, action: PayloadAction<Array<WorkshopType>>) {
      state.types = action.payload;
    },
    selectWorkshop(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
    selectWorkshopType(state, action: PayloadAction<number>) {
      state.selectedTypeId = action.payload;
    },
    setWorkshopNameFilter(state, action: PayloadAction<string>) {
      state.filters.name = action.payload;
    },
    setWorkshopYouthRegistrationsFilter(state, action: PayloadAction<RegistrationsFilterValue>) {
      state.filters.youthRegistrations = action.payload;
    },
    setWorkshopInstructorFilter(state, action: PayloadAction<number>) {
      state.filters.instructorId = action.payload;
    },
    setWorkshopGroupFilter(state, action: PayloadAction<number>) {
      state.filters.group = action.payload;
    },
    setWorkshopTypeFilter(state, action: PayloadAction<number>) {
      state.filters.typeId = action.payload;
    },
    setWorkshopLocationFilter(state, action: PayloadAction<number>) {
      state.filters.locationId = action.payload;
    },
    resetWorkshopsFilters(state) {
      state.filters = initialFilters;
    },
    deleteInstructorsFromWorkshops(state, action: PayloadAction<Array<number>>) {
      const deletedInstructorsIds = action.payload;

      state.list = state.list.map((workshop) => {
        const newInstructorsRegistered = workshop.instructorsRegistered.filter(
          (instructor) => !deletedInstructorsIds.includes(instructor.id),
        );

        if (workshop.instructorsRegistered.length === newInstructorsRegistered.length) {
          return workshop;
        }

        return {
          ...workshop,
          instructorsRegistered: newInstructorsRegistered,
        };
      });

      if (deletedInstructorsIds.includes(state.filters.instructorId)) {
        state.filters.locationId = 0;
      }
    },
    deleteLocationsFromWorkshops(state, action: PayloadAction<Array<number>>) {
      const deletedLocationsIds = action.payload;

      state.list = state.list.map((workshop) => {
        if (!workshop.location || !deletedLocationsIds.includes(workshop.location.id)) {
          return workshop;
        }

        return {
          ...workshop,
          location: null,
        };
      });

      if (deletedLocationsIds.includes(state.filters.locationId)) {
        state.filters.locationId = 0;
      }
    },
  },
});

export const {
  deleteLocationsFromWorkshops,
  deleteInstructorsFromWorkshops,
  resetWorkshopsFilters,
  selectWorkshop,
  selectWorkshopType,
  setWorkshopGroupFilter,
  setWorkshopLocationFilter,
  setWorkshopTypeFilter,
  setWorkshopNameFilter,
  setWorkshopInstructorFilter,
  setWorkshopYouthRegistrationsFilter,
  setWorkshopsList,
  setWorkshopTypes,
} = workshopsSlice.actions;

export default workshopsSlice.reducer;

export const syncWorkshops = (): AppThunk => async (dispatch) => {
  const workshops: Array<WorkshopDetailed> = await api.getWorkshops();
  dispatch(setWorkshopsList(workshops));
};

export const syncWorkshopTypes =
  (hasWorkshopOnly = false): AppThunk =>
  async (dispatch) => {
    const workshopTypes: Array<WorkshopType> = await api.getWorkshopTypes(hasWorkshopOnly);
    dispatch(setWorkshopTypes(workshopTypes));
  };

export const createOrUpdateWorkshopType =
  (workshopType: WorkshopType): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à créer ou modifier un atelier"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (workshopType.id !== undefined) {
        await api.updateWorkshopType(workshopType);
      } else {
        await api.insertWorkshopTypes([workshopType]);
      }

      await dispatch(syncWorkshops());
      await dispatch(syncWorkshopTypes());

      await dispatch(setSuccess('Atelier enregistré avec succès'));
    } catch (err: any) {
      console.error('Error while inserting or updating workshop type:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const createOrUpdateWorkshop =
  (workshop: WorkshopDetailed): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à créer ou modifier une session d'atelier"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (workshop.id !== undefined) {
        await api.updateWorkshop(workshop);
      } else {
        if (workshop.workshop_type_id === undefined) {
          workshop.workshop_type_id = workshop.type.id;
        }
        await api.insertWorkshops([workshop]);
      }

      await dispatch(syncWorkshops());
      await dispatch(syncWorkshopTypes());

      await dispatch(setSuccess('Session enregistrée avec succès'));
    } catch (err: any) {
      console.error('Error while inserting or updating workshop:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const importMultipleWorkshopTypes =
  (workshopTypes: Array<WorkshopType>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à importer des ateliers"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (workshopTypes.length === 0) {
        throw 'Aucun atelier à importer';
      }

      await api.insertWorkshopTypes(workshopTypes);
      await dispatch(syncWorkshops());
      await dispatch(syncWorkshopTypes());

      await dispatch(setSuccess('Ateliers importés avec succès'));
    } catch (err: any) {
      console.error('Error while importing workshops types', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const importMultipleWorkshops =
  (workshops: Array<Workshop>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à importer des sessions d'atelier"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (workshops.length === 0) {
        throw 'Aucune session à importer';
      }

      await api.insertWorkshops(workshops);
      await dispatch(syncWorkshops());
      await dispatch(syncWorkshopTypes());

      await dispatch(setSuccess('Sessions importés avec succès'));
    } catch (err: any) {
      console.error('Error while importing workshops', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const deleteWorkshopTypes =
  (workshopTypesIds: Array<number>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à supprimer des ateliers"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteWorkshopTypesByIds(workshopTypesIds);
      await dispatch(syncWorkshops());
      await dispatch(syncWorkshopTypes());

      await dispatch(setSuccess('Atelier(s) supprimé(s) avec succès'));
    } catch (err: any) {
      console.error('Error while deleting workshop types:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const deleteWorkshops =
  (workshopsIds: Array<number>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à supprimer des sessions d'atelier"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteWorkshopsByIds(workshopsIds);
      await dispatch(syncWorkshops());
      await dispatch(syncWorkshopTypes());

      await dispatch(setSuccess('Session(s) supprimée(s) avec succès'));
    } catch (err: any) {
      console.error('Error while deleting workshops:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const generateTimesheetForSelectedWorkshop = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const selectedWorkshop = getSelectedWorkshop(getState());

    if (!selectedWorkshop) {
      dispatch(setLoading(false));
      return;
    }

    await (window as any).electron.pdfGenerator.generateTimesheet(selectedWorkshop);
  } catch (err: any) {
    console.error('Error while generating timesheet', err);
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};

export const generateAllTimesheets =
  (progressCallback: (value: number) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await dispatch(syncWorkshops());
      const workshops = getWorkshops(getState());
      if (workshops.length > 0) {
        await (window as any).electron.pdfGenerator.generateAllTimesheets(workshops, progressCallback);
      }
    } catch (err: any) {
      console.error('Error while generating all timesheets', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };
