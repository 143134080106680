import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducer';
import {
  GroupedIdentifiedItems,
  TimeslotsById,
  WorkshopDetailed,
  WorkshopsByFirstLetter,
  WorkshopsFilters,
  WorkshopsSeatsById,
  WorkshopType,
  WorkshopTypeDetails,
  WorkshopTypesById,
  WorkshopTypesDetailsById,
} from '../../@types/data';
import { LinkType, RegistrationsFilterValue } from '../../@types/enums';

export const getWorkshops = (state: RootState): Array<WorkshopDetailed> => state.workshops.list;
export const getWorkshopTypes = (state: RootState): Array<WorkshopType> => state.workshops.types;
export const getSelectedWorkshopId = (state: RootState): number | null => state.workshops.selectedId;
export const getSelectedWorkshopTypeId = (state: RootState): number | null => state.workshops.selectedTypeId;
export const getWorkshopsFilters = (state: RootState): WorkshopsFilters => state.workshops.filters;

export const getWorkshopsFiltered = createSelector(
  getWorkshops,
  getWorkshopsFilters,
  (workshops, filters): Array<WorkshopDetailed> => {
    // No filter apply
    if (
      filters.name.length === 0 &&
      filters.youthRegistrations === RegistrationsFilterValue.ALL &&
      filters.group === 0 &&
      filters.typeId === 0 &&
      filters.locationId === 0 &&
      filters.instructorId === 0
    ) {
      return workshops;
    }

    return workshops.filter((workshop) => {
      // Filter on name
      try {
        const nameFilter = RegExp(filters.name, 'i').test(workshop.type.name);
        if (!nameFilter) {
          return false;
        }
      } catch (err) {
        return false;
      }

      // Filter on type
      if (filters.typeId !== 0 && workshop.type.id !== filters.typeId) {
        return false;
      }

      // Filter on instructor
      const instructorFilter =
        filters.instructorId === 0 || // All instructors
        (filters.instructorId === -1 && workshop.instructorsRegistered.length === 0) || // No instructor
        workshop.instructorsRegistered.find((instructor) => instructor.id === filters.instructorId) !== undefined; // Matching instructor
      if (!instructorFilter) {
        return false;
      }

      // Filter on location
      const locationFilter =
        filters.locationId === 0 || // All locations
        (filters.locationId === -1 && workshop.location?.id === null) || // Unknown location
        workshop.location?.id === filters.locationId; // Matching location
      if (!locationFilter) {
        return false;
      }

      // Filter on group
      if (filters.group !== 0 && workshop.group !== filters.group) {
        return false;
      }

      // Filter on registrations
      let youthRegistrationsFilter;
      switch (filters.youthRegistrations) {
        case RegistrationsFilterValue.NO_WISH:
          youthRegistrationsFilter = !workshop.type.youthWishes || workshop.type.youthWishes.length === 0;
          break;

        case RegistrationsFilterValue.NO_REGISTRATION:
          youthRegistrationsFilter = workshop.youthRegistered.length === 0;
          break;

        case RegistrationsFilterValue.NOT_SATISFIED:
          youthRegistrationsFilter = workshop.youthRegistered.length < workshop.numSeats;
          break;

        default:
          youthRegistrationsFilter = true;
          break;
      }

      return youthRegistrationsFilter;
    });
  },
);

export const getSelectedWorkshop = createSelector(
  getWorkshops,
  getSelectedWorkshopId,
  (workshops, selectedWorkshopId): WorkshopDetailed | null => {
    return workshops.find((workshop) => workshop.id === selectedWorkshopId) || null;
  },
);

export const getSelectedWorkshopType = createSelector(
  getWorkshopTypes,
  getSelectedWorkshopTypeId,
  (workshopTypes, selectedWorkshopTypeId): WorkshopType | null => {
    return workshopTypes.find((workshopTypes) => workshopTypes.id === selectedWorkshopTypeId) || null;
  },
);

export const getWorkshopsByFirstLetter = createSelector(getWorkshopsFiltered, (workshops): WorkshopsByFirstLetter => {
  return workshops.reduce((result, workshop) => {
    const firstLetter = workshop.type.name.charAt(0).toUpperCase();

    if (!result[firstLetter]) {
      result[firstLetter] = [];
    }

    result[firstLetter].push(workshop);

    return result;
  }, {});
});

export const getWorkshopTypesByFirstLetter = createSelector(
  getWorkshopTypes,
  (workshopTypes): GroupedIdentifiedItems => {
    return workshopTypes.reduce((result, workshopType) => {
      const firstLetter = workshopType.name.charAt(0).toUpperCase();

      if (!result[firstLetter]) {
        result[firstLetter] = [];
      }

      result[firstLetter].push(workshopType);

      return result;
    }, {});
  },
);

export const getWorkshopTypesById = createSelector(getWorkshopTypes, (workshopTypes): WorkshopTypesById => {
  return workshopTypes.reduce((result, workshopType) => {
    result[workshopType.id] = workshopType;
    return result;
  }, {});
});

export const getWorkshopsRemainingSeatsById = createSelector(getWorkshops, (workshops): WorkshopsSeatsById => {
  return workshops.reduce((result, workshop) => {
    const numYouthRegistered = workshop.youthRegistered.length;
    const numSeats = workshop.numSeats;

    result[workshop.id] = numYouthRegistered < numSeats ? numSeats - numYouthRegistered : 0;

    return result;
  }, {});
});

export const getWorkshopsGroups = createSelector(getWorkshops, (workshops): Array<number> => {
  const groups = workshops.reduce((result, workshop) => {
    if (workshop.group) {
      result.add(workshop.group);
    }
    return result;
  }, new Set<number>());

  return Array.from(groups).sort((a, b) => a - b);
});

export const getWorkshopTypesDetails = createSelector(getWorkshopTypes, (workshopTypes): WorkshopTypesDetailsById => {
  return workshopTypes.reduce((result, workshopType) => {
    const details: WorkshopTypeDetails = {
      numSeats: 0,
      numRegistrations: 0,
    };

    if (!workshopType.workshops || workshopType.workshops.length === 0) {
      result[workshopType.id] = details;
    } else if (workshopType.links === LinkType.AND) {
      details.numSeats = Infinity;
      result[workshopType.id] = workshopType.workshops.reduce((acc, workshop) => {
        return {
          numSeats: Math.min(acc.numSeats, workshop.numSeats),
          numRegistrations: Math.max(acc.numRegistrations, workshop.numYouthRegistered),
        };
      }, details);
    } else {
      result[workshopType.id] = workshopType.workshops.reduce((acc, workshop) => {
        return {
          numSeats: acc.numSeats + workshop.numSeats,
          numRegistrations: acc.numRegistrations + workshop.numYouthRegistered,
        };
      }, details);
    }

    return result;
  }, {});
});

export const getWorkshopsTimeslots = createSelector(getWorkshops, (workshops): TimeslotsById => {
  return workshops.reduce((result, workshop) => {
    result[workshop.id] = [
      {
        startDate: workshop.startDate,
        endDate: workshop.endDate,
      },
    ];

    return result;
  }, {} as TimeslotsById);
});

export const getConstrainedWorkshopsTimeslots = createSelector(
  getWorkshopTypesById,
  getWorkshops,
  (workshopTypes, workshops): TimeslotsById => {
    return workshops.reduce((result, workshop) => {
      switch (workshop.type.links) {
        case LinkType.OR:
          result[workshop.id] = [
            {
              startDate: workshop.startDate,
              endDate: workshop.endDate,
            },
          ];
          break;

        case LinkType.AND:
          const workshopType = workshopTypes[workshop.type.id];
          if (!workshopType || !workshopType.workshops || workshopType.workshops.length === 0) {
            break;
          }

          result[workshop.id] = workshopType.workshops?.map((w) => ({
            startDate: w.startDate,
            endDate: w.endDate,
          }));
          break;

        default:
          break;
      }

      return result;
    }, {} as TimeslotsById);
  },
);
