import { AutoRegistrationData, Timeslot, WorkshopDetailed, WorkshopsIdsByPersonId } from '../@types/data';
import { LinkType } from '../@types/enums';

// Returns true if timeslots do not overlap
export const checkTime = (busyTimeslots: Array<Timeslot>, timeslots: Array<Timeslot>): boolean => {
  for (const busyTimeslot of busyTimeslots) {
    for (const timeslot of timeslots) {
      if (busyTimeslot.startDate < timeslot.endDate && busyTimeslot.endDate > timeslot.startDate) {
        return false;
      }
    }
  }

  return true;
};

export const shuffleArray = (input: Array<any>, readOnly = false): Array<any> => {
  const array = readOnly ? [...input] : input;

  // Uses the Durstenfeld (Fisher-Yates) algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
};

export const toFullName = (firstname?: string, lastname?: string): string => {
  let fullName = '';
  fullName += lastname ?? '';
  if (firstname && lastname) {
    fullName += ' ';
  }
  fullName += firstname ?? '';

  return fullName;
};

export const autoRegister = (
  autoRegistrationData: AutoRegistrationData,
  constrainWorkshopsLinks = true,
): WorkshopsIdsByPersonId => {
  // ****** Prepare data : to store registrations locally to all apply at the end
  const localRegistrations: WorkshopsIdsByPersonId = autoRegistrationData.persons.list.reduce((result, person) => {
    result[person.id] = person.registrations.map((workshop) => workshop.id); // Keep current registrations
    return result;
  }, {}); // { personId1: [WorkshopId1, WorkshopId2] }

  // Auto registration is done if all workshop types wishes have been completely tried
  const isDone = () => {
    for (const wish of Object.values(autoRegistrationData.wishes.workshopTypes)) {
      if (wish.min < wish.max) {
        return false;
      }
    }

    return true;
  };

  while (!isDone()) {
    // For each person
    for (const person of autoRegistrationData.persons.list) {
      // Get current person workshop types wishes
      const personWorkshopTypesWishes = autoRegistrationData.wishes.workshopTypes[person.id];
      // Get current person locations wishes
      const personLocationsIdsWishes = autoRegistrationData.wishes.locations[person.id];

      // If all workshop types wishes have been tried, skip
      if (personWorkshopTypesWishes.min >= personWorkshopTypesWishes.max) {
        continue;
      }

      // Get current wish index
      let nWish = personWorkshopTypesWishes.min;
      // Try to register until all workshop types wishes have been tried (or early break in loop if successfully registered
      while (nWish < personWorkshopTypesWishes.max) {
        // Get workshop name (type) matching the current wish
        const workshopType = personWorkshopTypesWishes.wishes[nWish];
        // Get list of workshops belonging to this workshop type and the person group (if any)
        let workshops = autoRegistrationData.workshopTypes[workshopType.id].workshops?.filter(
          (workshop) => !(person as any).group || workshop.group === (person as any).group,
        );
        if (!workshops || workshops.length === 0) {
          // No workshop => skip
          nWish += 1;
          continue;
        }
        const numUnfilteredWorkshops = workshops.length;

        // Filter workshops to only keep workshops at allowed locations
        workshops = workshops.filter(
          (workshop) => workshop.location?.id && personLocationsIdsWishes[workshop.location.id],
        );
        if (
          constrainWorkshopsLinks &&
          workshopType.links == LinkType.AND &&
          workshops.length < numUnfilteredWorkshops
        ) {
          // If one of the workshop of an AND workshop type is not reachable => skip
          nWish += 1;
          continue;
        }

        // Sort workshops according to locations wishes rank
        workshops = workshops.sort(
          (a, b) =>
            personLocationsIdsWishes[a.location?.id as number] - personLocationsIdsWishes[b.location?.id as number],
        );

        let workshopsToRegister: Array<WorkshopDetailed> = [];

        for (const workshop of workshops) {
          // Check if : there is a remaining seat AND if the person is available at the given timeslot
          if (
            autoRegistrationData.workshops.remainingSeats[workshop.id] > 0 &&
            checkTime(
              autoRegistrationData.persons.busyTimeslots[person.id],
              autoRegistrationData.workshops.timeslots[workshop.id],
            )
          ) {
            workshopsToRegister.push(workshop as unknown as WorkshopDetailed);

            if (constrainWorkshopsLinks && workshopType.links === LinkType.OR) {
              break;
            }
          } else if (constrainWorkshopsLinks && workshopType.links === LinkType.AND) {
            workshopsToRegister = [];
            break;
          }
        }

        if (workshopsToRegister.length > 0) {
          // Successful registration : skip to next young
          localRegistrations[person.id] = localRegistrations[person.id].concat(workshopsToRegister.map((w) => w.id));

          for (const workshopToRegister of workshopsToRegister) {
            // Add the new timeslots to the list of young busy timeslots
            autoRegistrationData.persons.busyTimeslots[person.id].push({
              startDate: workshopToRegister.startDate,
              endDate: workshopToRegister.endDate,
            });

            // Decrease workshop remaining seats number
            autoRegistrationData.workshops.remainingSeats[workshopToRegister.id] -= 1;
          }

          break;
        } else {
          // Wish has not been fulfilled : try next wish
          nWish += 1;
        }
      }

      personWorkshopTypesWishes.min = nWish + 1;
    }
  }

  return localRegistrations;
};
