import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import ImportIcon from '@mui/icons-material/Publish';
import ClearIcon from '@mui/icons-material/Clear';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import AccordionFilters from './AccordionFilters';
import DetailsFields from '../components/DetailsFields';
import GroupedSelectableList from '../components/GroupedSelectableList';
import { GroupedIdentifiedItems, IdentifiedItem, IdentifiedItemsSelected } from '../@types/data';

const useStyles = makeStyles()((theme) => {
  return {
    panel: {
      height: '100%',
    },
    subPanel: {
      height: '100%',
      paddingBottom: '0 !important',
      overflowY: 'auto',
    },
    subSubPanel: {
      height: '100%',
      paddingBottom: '0 !important',
    },
    subContainer: {
      height: '100%',
      flexWrap: 'nowrap',
    },
    listPanel: {
      height: '100%',
      paddingBottom: '0 !important',
      overflow: 'auto',
    },
    paper: {
      overflowY: 'auto',
      width: '100%',
      height: '100%',
      padding: 20,
    },
    formTitle: {
      marginBottom: 20,
    },
    button: {
      width: '100%',
    },
    successButton: {
      width: '100%',
      backgroundColor: theme.palette.success.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
        boxShadow: 'none',
      },
    },
  };
});

type AdminPanelProps = {
  selected: IdentifiedItem;
  setSelected: (value: IdentifiedItem) => void;
  values: GroupedIdentifiedItems;
  valuesTextFn: (value: IdentifiedItem) => string;
  valuesSubTextFn?: (value: IdentifiedItem) => string;
  onNewClicked: () => void;
  onSaveClicked: () => void;
  onDeleteClicked: (ids: Array<number>) => void;
  onImport: (data: Array<Array<string>>) => void;
  filters?: any;
  fields: any;
};
const AdminPanel: React.FC<AdminPanelProps> = (props: AdminPanelProps) => {
  const { classes } = useStyles();

  const {
    selected,
    setSelected,
    values,
    valuesTextFn,
    valuesSubTextFn,
    onNewClicked,
    onSaveClicked,
    onDeleteClicked,
    onImport,
    filters,
    fields,
  } = props;

  const [multiSelection, setMultiSelection] = useState<IdentifiedItemsSelected>({} as IdentifiedItemsSelected);
  const [importDialogOpened, setImportDialogOpened] = useState(false);
  const [removalDialogOpened, setRemovalDialogOpened] = useState(false);
  const [currentImportText, setCurrentImportText] = useState('');

  const onMultiSelectionChanged = useCallback(
    (item: IdentifiedItem) => {
      setMultiSelection((multiSelection) => ({
        ...multiSelection,
        [item.id]: !multiSelection[item.id] ?? true,
      }));
    },
    [setMultiSelection],
  );

  const multiSelectAll = useCallback(() => {
    const selection = Object.values(values).reduce((result, items) => {
      items.forEach((item) => {
        result[item.id] = true;
      });
      return result;
    }, {});

    setMultiSelection(selection);
  }, [setMultiSelection, values]);

  const multiUnselectAll = useCallback(() => {
    setMultiSelection({});
  }, [setMultiSelection]);

  const onBulkDeleteClicked = useCallback(() => {
    setRemovalDialogOpened(true);
  }, [setRemovalDialogOpened]);

  const cancelRemoval = useCallback(() => {
    setRemovalDialogOpened(false);
  }, [setRemovalDialogOpened]);

  const processRemoval = useCallback(() => {
    const ids = Object.entries(multiSelection)
      .filter(([, selected]) => selected)
      .map(([id]) => parseInt(id, 10));

    onDeleteClicked(ids);
    setMultiSelection({});
    setRemovalDialogOpened(false);
  }, [multiSelection, onDeleteClicked]);

  const isSelectionEmpty = useMemo(() => {
    return !Object.entries(multiSelection).some(([, selected]) => selected);
  }, [multiSelection]);

  const openImportDialog = useCallback(() => {
    setImportDialogOpened(true);
  }, [setImportDialogOpened]);

  const closeImportDialog = useCallback(() => {
    setImportDialogOpened(false);
    setCurrentImportText('');
  }, [setImportDialogOpened]);

  const processImportation = useCallback(() => {
    if (currentImportText) {
      const lines = currentImportText.split('\n').filter((line) => line.length > 0);
      const data = lines.map((line) => line.split('\t'));
      onImport(data);
      setCurrentImportText('');
    }

    setImportDialogOpened(false);
  }, [currentImportText]);

  return (
    <Grid container spacing={1} className={classes.panel}>
      <Dialog open={removalDialogOpened} onClose={cancelRemoval}>
        <DialogTitle>Confirmation - Suppression</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Attention, l&apos;action que vous vous apprêtez à faire va supprimer les éléments actuellement sélectionnés
            de manière irrémédiable.
            <br />
            Voulez-vous continuer ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelRemoval} color="primary">
            Non
          </Button>
          <Button onClick={processRemoval} color="primary" autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={importDialogOpened} onClose={closeImportDialog} maxWidth="lg" fullWidth>
        <DialogTitle>Importer depuis Excel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Copier / coller les données à importer depuis Excel (dans l&apos;ordre des champs du formulaire, sans
            en-tête).
          </DialogContentText>
          <TextField
            label="Données Excel"
            value={currentImportText}
            onChange={(event) => setCurrentImportText(event.target.value)}
            variant="outlined"
            multiline
            rows={20}
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImportDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={processImportation} color="primary">
            Importer
          </Button>
        </DialogActions>
      </Dialog>

      <Grid item xs={12} md={4} className={classes.subPanel}>
        <Grid container direction="column" spacing={1} className={classes.subContainer}>
          {filters && (
            <Grid item>
              <AccordionFilters {...filters} />
            </Grid>
          )}
          <Grid item className={classes.listPanel}>
            <GroupedSelectableList
              groupedItems={values}
              selectedItem={selected}
              onItemClick={setSelected}
              itemText={valuesTextFn}
              itemSubText={valuesSubTextFn}
              order="ASC"
              multiSelection={multiSelection}
              onMultiSelect={onMultiSelectionChanged}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} className={classes.subPanel}>
        <Grid container direction="column" spacing={1} className={classes.subContainer}>
          <Grid item className={classes.panel}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6" gutterBottom className={classes.formTitle}>
                Ajouter / Editer
              </Typography>
              <Grid container direction="column" spacing={3}>
                <Grid item xs={12}>
                  <DetailsFields item={selected} editable fields={fields} spacing={2} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={5} direction="row" justifyContent="space-around">
                    <Grid item xs={12} sm={5}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={onNewClicked}
                      >
                        Nouveau
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button
                        className={classes.successButton}
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={onSaveClicked}
                      >
                        Enregistrer
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item className={classes.subSubPanel}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6" gutterBottom className={classes.formTitle}>
                Actions globales
              </Typography>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={5} direction="row" justifyContent="space-around">
                    <Grid item xs={12} sm={5}>
                      <Button className={classes.button} onClick={multiSelectAll}>
                        Tout sélectionner
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button className={classes.button} onClick={multiUnselectAll} disabled={isSelectionEmpty}>
                        Tout désélectionner
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={5} direction="row" justifyContent="space-around">
                    <Grid item xs={12} sm={5}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        startIcon={<ImportIcon />}
                        onClick={openImportDialog}
                      >
                        Importer
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        startIcon={<ClearIcon />}
                        onClick={onBulkDeleteClicked}
                        disabled={isSelectionEmpty}
                      >
                        Supprimer
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminPanel;
