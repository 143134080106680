import React, { useCallback, useState } from 'react';
import { momentLocalizer, NavigateAction, View, Calendar as BigCalendar } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';
import CalendarToolbar from './CalendarToolbar';

import '../styles/calendar.css';
import { ColoredEvent, Timeslot } from '../@types/data';
import { checkTime } from '../utils/utils';

const localizer = momentLocalizer(moment);

type CalendarProps = {
  events: Array<ColoredEvent>;
  defaultDate?: Date;
  showToolbar?: boolean;
  disabledTimeslots?: Array<Timeslot>;
};

const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { events, defaultDate, showToolbar, disabledTimeslots } = props;

  const [currentDate, setCurrentDate] = useState(defaultDate ?? moment().startOf('day').toDate());

  const eventStyleGetter = useCallback((eventData) => {
    const getHashCode = (str: string) => {
      let hash = 0;

      if (str.length === 0) return hash;

      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    };

    const intToHSL = (value: number) => {
      const shortened = value % 360;
      return 'hsl(' + shortened + ',100%,80%)';
    };

    return {
      style: {
        backgroundColor: intToHSL(getHashCode(eventData.colorIdText ?? eventData.title)),
        color: 'inherit',
      },
    };
  }, []);

  const slotStyleGetter = useCallback(
    (date: Date) => {
      if (!disabledTimeslots) {
        return {};
      }

      const currentTimeslot = [
        {
          startDate: moment(date).unix(),
          endDate: moment(date).add(30, 'm').unix(),
        },
      ];

      if (checkTime(disabledTimeslots, currentTimeslot)) {
        return {};
      }

      return {
        style: {
          backgroundColor: '#eeeeee',
          color: 'inherit',
        },
      };
    },
    [disabledTimeslots],
  );

  const onNavigate = (newDate: Date, view: View, action: NavigateAction): void => {
    switch (action) {
      case 'TODAY':
        setCurrentDate(moment().startOf('day').toDate());
        break;

      case 'NEXT':
        setCurrentDate(moment(currentDate).add(1, 'week').toDate());
        break;

      case 'PREV':
        setCurrentDate(moment(currentDate).subtract(1, 'week').toDate());
        break;

      default:
        setCurrentDate(newDate);
        break;
    }
  };

  return (
    <React.Fragment>
      {showToolbar !== false && <CalendarToolbar onNavigate={onNavigate} currentDate={currentDate} />}
      <BigCalendar
        localizer={localizer}
        events={events}
        eventPropGetter={eventStyleGetter}
        slotPropGetter={slotStyleGetter}
        defaultView="work_week"
        date={currentDate}
        views={{ work_week: true }}
        toolbar={false}
        onNavigate={onNavigate}
        min={moment('8', 'H').toDate()}
        max={moment('17', 'H').toDate()}
      />
    </React.Fragment>
  );
};

export default Calendar;
