import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import PasswordInput from '../components/PasswordInput';
import { getCurrentConfig } from '../features/config/configSelector';
import { loadConfig, saveNewConfig } from '../features/config/configSlice';
import { LocalConfigSchema } from '../@types/data';
import { isSignedIn } from '../features/users/usersSelector';
import { changePassword } from '../features/users/usersSlice';

const useStyles = makeStyles()(() => ({
  paper: {
    width: '100%',
    padding: 20,
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}));

const Config: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [config, setConfig] = useState<LocalConfigSchema>({} as LocalConfigSchema);
  const [newPassword, setNewPassword] = useState('');

  const currentConfig: LocalConfigSchema = useSelector(getCurrentConfig);
  const signedIn = useSelector(isSignedIn);

  useEffect(() => {
    dispatch(loadConfig());
  }, []);

  useEffect(() => {
    setConfig(currentConfig);
  }, [currentConfig]);

  const handleServerConfigChange = useCallback(
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfig((prevConfig) => {
        return {
          ...prevConfig,
          server: {
            ...prevConfig.server,
            [prop]: event.target.value,
          },
        };
      });
    },
    [],
  );

  const handleNewPasswordChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword(event.target.value);
    },
    [setNewPassword],
  );

  const saveConfig = useCallback(async () => {
    await dispatch(saveNewConfig(config, signedIn));
    if (newPassword !== '') {
      await dispatch(changePassword(newPassword));
      setNewPassword('');
    }
  }, [config, newPassword, signedIn]);

  return (
    <Grid container spacing={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h6" sx={{ marginBottom: '20px' }}>
              Serveur
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Adresse / URL"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={config?.server?.address || ''}
                  onChange={handleServerConfigChange('address')}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {signedIn && (
          <Grid item>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6" sx={{ marginBottom: '20px' }}>
                Changer le mot de passe
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <PasswordInput
                    label="Nouveau mot de passe"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    shrink
                    formControlProps={{ fullWidth: true, size: 'small' }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item className={classes.buttons}>
          <Button variant="contained" color="primary" startIcon={<SaveIcon />} onClick={saveConfig}>
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Config;
