import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
  getYouthByFirstLetter,
  getYouthCounselors,
  getYouthFilters,
  getYouthGroups,
} from '../features/youth/youthSelectors';
import TabsLayout from '../components/TabsLayout';
import {
  GroupedIdentifiedItems,
  IdentifiedItem,
  Instructor,
  InstructorsFilters,
  Location,
  User,
  UserBase,
  Workshop,
  WorkshopDetailed,
  WorkshopsFilters,
  WorkshopType,
  YoungBase,
  YoungDetailed,
  YouthFilters,
} from '../@types/data';
import AdminPanel from '../containers/AdminPanel';
import { LinkType, RegistrationsFilterValue, Role } from '../@types/enums';
import {
  createOrUpdateYoung,
  deleteYouth,
  importMultipleYouth,
  resetYouthFilters,
  setYoungCounselorFilter,
  setYoungGroupFilter,
  setYoungNameFilter,
} from '../features/youth/youthSlice';
import {
  getWorkshopsByFirstLetter,
  getWorkshopsFilters,
  getWorkshopsGroups,
  getWorkshopTypes,
  getWorkshopTypesByFirstLetter,
} from '../features/workshops/workshopsSelectors';
import { setError } from '../features/globalSlice';
import {
  createOrUpdateWorkshop,
  createOrUpdateWorkshopType,
  deleteWorkshops,
  deleteWorkshopTypes,
  importMultipleWorkshops,
  importMultipleWorkshopTypes,
  resetWorkshopsFilters,
  setWorkshopGroupFilter,
  setWorkshopLocationFilter,
  setWorkshopNameFilter,
  setWorkshopTypeFilter,
} from '../features/workshops/workshopsSlice';
import { getUsersByFirstLetter, isAdmin } from '../features/users/usersSelector';
import { createOrUpdateUser, deleteUsers, importMultipleUsers } from '../features/users/usersSlice';
import { getLocations, getLocationsByFirstLetter } from '../features/locations/locationsSelectors';
import { createOrUpdateLocation, deleteLocations, importMultipleLocations } from '../features/locations/locationsSlice';
import { toFullName } from '../utils/utils';
import {
  getInstructors,
  getInstructorsByFirstLetter,
  getInstructorsFilters,
} from '../features/instructors/instructorsSelectors';
import {
  createOrUpdateInstructor,
  deleteInstructors,
  importMultipleInstructors,
  resetInstructorsFilters,
  setInstructorNameFilter,
} from '../features/instructors/instructorsSlice';

const useStyles = makeStyles()((theme) => {
  const toolbarHeight = theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight as string, 10) : 0;

  return {
    root: {
      height: `calc(100vh - ${toolbarHeight * 2}px)`,
    },
    warningDialog: {
      zIndex: `${theme.zIndex.modal + 2} !important` as unknown as number,
    },
  };
});

const Admin: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const youngLastnameInputRef = React.createRef<HTMLInputElement>();
  const locationNameInputRef = React.createRef<HTMLInputElement>();
  const instructorLastnameInputRef = React.createRef<HTMLInputElement>();
  const workshopTypeNameInputRef = React.createRef<HTMLInputElement>();
  const workshopNameInputRef = React.createRef<HTMLInputElement>();
  const userEmailInputRef = React.createRef<HTMLInputElement>();

  const [warningDialogOpened, setWarningDialogOpened] = useState(true);
  const [currentTab, setCurrentTab] = useState('0');
  const [currentYoung, setCurrentYoung] = useState<YoungDetailed>({} as YoungDetailed);
  const [currentLocation, setCurrentLocation] = useState<Location>({} as Location);
  const [currentInstructor, setCurrentInstructor] = useState<Instructor>({} as Instructor);
  const [currentWorkshopType, setCurrentWorkshopType] = useState<WorkshopType>({} as WorkshopType);
  const [currentWorkshop, setCurrentWorkshop] = useState<WorkshopDetailed>({} as WorkshopDetailed);
  const [currentUser, setCurrentUser] = useState<User>({} as User);

  const youthByFirstLetter: GroupedIdentifiedItems = useSelector(getYouthByFirstLetter);
  const youthFilters: YouthFilters = useSelector(getYouthFilters);
  const youthGroups: Array<number> = useSelector(getYouthGroups);
  const youthCounselors: Array<Instructor> = useSelector(getYouthCounselors);

  const locations: Array<Location> = useSelector(getLocations);
  const locationsByFirstLetter: GroupedIdentifiedItems = useSelector(getLocationsByFirstLetter);

  const workshopTypes: Array<WorkshopType> = useSelector(getWorkshopTypes);
  const workshopTypesByFirstLetter: GroupedIdentifiedItems = useSelector(getWorkshopTypesByFirstLetter);

  const workshopsByFirstLetter: GroupedIdentifiedItems = useSelector(getWorkshopsByFirstLetter);
  const workshopsFilters: WorkshopsFilters = useSelector(getWorkshopsFilters);
  const workshopsGroups: Array<number> = useSelector(getWorkshopsGroups);

  const instructors: Array<Instructor> = useSelector(getInstructors);
  const instructorsFilters: InstructorsFilters = useSelector(getInstructorsFilters);
  const instructorsByFirstLetter: GroupedIdentifiedItems = useSelector(getInstructorsByFirstLetter);

  const usersByFirstLetter: GroupedIdentifiedItems = useSelector(getUsersByFirstLetter);

  const signedAsAdmin = useSelector(isAdmin);

  const tabs = useMemo(() => {
    return [
      { name: 'Jeunes' },
      { name: 'Intervenants' },
      { name: 'Lieux', disabled: !signedAsAdmin },
      { name: 'Ateliers', disabled: !signedAsAdmin },
      { name: 'Sessions', disabled: !signedAsAdmin },
      { name: 'Utilisateurs', disabled: !signedAsAdmin },
    ];
  }, [signedAsAdmin]);

  const closeWarningDialog = useCallback(() => {
    setWarningDialogOpened(false);
  }, [setWarningDialogOpened]);

  const onYoungClicked = useCallback((young: IdentifiedItem) => {
    setCurrentYoung({ ...young } as YoungDetailed);
  }, []);

  const onLocationClicked = useCallback((location: IdentifiedItem) => {
    setCurrentLocation({ ...location } as Location);
  }, []);

  const onInstructorClicked = useCallback((instructor: IdentifiedItem) => {
    setCurrentInstructor({ ...instructor } as Instructor);
  }, []);

  const onWorkshopTypeClicked = useCallback((workshopType: IdentifiedItem) => {
    setCurrentWorkshopType({ ...workshopType } as WorkshopType);
  }, []);

  const onWorkshopClicked = useCallback((workshop: IdentifiedItem) => {
    setCurrentWorkshop({ ...workshop } as WorkshopDetailed);
  }, []);

  const onUserClicked = useCallback((user: IdentifiedItem) => {
    setCurrentUser({ ...user } as User);
  }, []);

  const computeYoungText = useCallback((young: IdentifiedItem) => {
    return toFullName((young as YoungDetailed).firstname, (young as YoungDetailed).lastname);
  }, []);

  const computeLocationText = useCallback((location: IdentifiedItem) => {
    return (location as Location).name;
  }, []);

  const computeInstructorText = useCallback((instructor: IdentifiedItem) => {
    return toFullName((instructor as Instructor).firstname, (instructor as Instructor).lastname);
  }, []);

  const computeWorkshopTypeText = useCallback((workshopType: IdentifiedItem) => {
    return (workshopType as WorkshopType).name;
  }, []);

  const computeWorkshopText = useCallback((workshop: IdentifiedItem) => {
    return (workshop as WorkshopDetailed).type.name;
  }, []);

  const computeUserText = useCallback((user: IdentifiedItem) => {
    return (user as User).email;
  }, []);

  const computeWorkshopSubText = useCallback((workshop: IdentifiedItem) => {
    const { startDate, endDate } = workshop as WorkshopDetailed;

    const formattedStartDate = moment.unix(startDate).format('DD/MM/YYYY HH:mm');
    const formattedEndDate = moment.unix(endDate).format('DD/MM/YYYY HH:mm');

    return `${formattedStartDate} - ${formattedEndDate}`;
  }, []);

  const initNewYoung = useCallback(() => {
    youngLastnameInputRef.current?.focus();
    setCurrentYoung({} as YoungDetailed);
  }, [youngLastnameInputRef]);

  const saveCurrentYoung = useCallback(async () => {
    await dispatch(createOrUpdateYoung(currentYoung));
    setCurrentYoung({} as YoungDetailed);
  }, [currentYoung]);

  const deleteSelectedYouth = useCallback(async (ids: Array<number>) => {
    await dispatch(deleteYouth(ids));
    setCurrentYoung({} as YoungDetailed);
  }, []);

  const importYouth = useCallback(
    async (data: Array<Array<string>>) => {
      const youth: Array<YoungBase> = [];

      data.forEach((line) => {
        if (line.length === 0) {
          return;
        }

        const young: YoungBase = {} as YoungBase;
        young.lastname = line[0];

        if (line.length > 1) {
          young.firstname = line[1];
        } else {
          young.firstname = '';
        }

        if (line.length > 2) {
          try {
            young.group = parseInt(line[2], 10);
          } catch (err) {
            console.error('Failed to parse young group to int while importing data:', line[2]);
          }
        } else {
          young.group = 0;
        }

        if (line.length > 3) {
          const counselor = instructors.find(
            (instructor) => toFullName(instructor.firstname, instructor.lastname) === line[3],
          );
          if (counselor) {
            young.counselor = counselor;
          }
        }

        youth.push(young);
      });

      await dispatch(importMultipleYouth(youth));

      setCurrentYoung({} as YoungDetailed);
    },
    [instructors],
  );

  const initNewLocation = useCallback(() => {
    locationNameInputRef.current?.focus();
    setCurrentLocation({} as Location);
  }, [locationNameInputRef]);

  const saveCurrentLocation = useCallback(async () => {
    await dispatch(createOrUpdateLocation(currentLocation));
    setCurrentLocation({} as Location);
  }, [currentLocation]);

  const deleteSelectedLocations = useCallback(async (ids: Array<number>) => {
    await dispatch(deleteLocations(ids));
    setCurrentLocation({} as Location);
  }, []);

  const importLocations = useCallback(async (data: Array<Array<string>>) => {
    const locations: Array<Location> = [];

    data.forEach((line) => {
      if (line.length === 0) {
        return;
      }

      const location = {} as Location;
      location.name = line[0];

      locations.push(location);
    });

    await dispatch(importMultipleLocations(locations));

    setCurrentLocation({} as Location);
  }, []);

  const initNewInstructor = useCallback(() => {
    instructorLastnameInputRef.current?.focus();
    setCurrentInstructor({} as Instructor);
  }, [instructorLastnameInputRef]);

  const saveCurrentInstructor = useCallback(async () => {
    await dispatch(createOrUpdateInstructor(currentInstructor));
    setCurrentInstructor({} as Instructor);
  }, [currentInstructor]);

  const deleteSelectedInstructors = useCallback(async (ids: Array<number>) => {
    await dispatch(deleteInstructors(ids));
    setCurrentInstructor({} as Instructor);
  }, []);

  const importInstructors = useCallback(async (data: Array<Array<string>>) => {
    const instructors: Array<Instructor> = [];

    data.forEach((line) => {
      if (line.length === 0) {
        return;
      }

      const instructor = {} as Instructor;
      instructor.lastname = line[0];

      if (line.length > 1) {
        instructor.firstname = line[1];
      } else {
        instructor.firstname = '';
      }

      instructors.push(instructor);
    });

    await dispatch(importMultipleInstructors(instructors));

    setCurrentInstructor({} as Instructor);
  }, []);

  const initNewWorkshopType = useCallback(() => {
    workshopTypeNameInputRef.current?.focus();
    setCurrentWorkshopType({} as WorkshopType);
  }, [workshopTypeNameInputRef]);

  const saveCurrentWorkshopType = useCallback(async () => {
    await dispatch(createOrUpdateWorkshopType(currentWorkshopType));
    setCurrentWorkshopType({} as WorkshopType);
  }, [currentWorkshopType]);

  const deleteSelectedWorkshopTypes = useCallback(async (ids: Array<number>) => {
    await dispatch(deleteWorkshopTypes(ids));
    setCurrentWorkshopType({} as WorkshopType);
  }, []);

  const importWorkshopTypes = useCallback(async (data: Array<Array<string>>) => {
    const workshopTypes: Array<WorkshopType> = [];

    data.forEach((line) => {
      if (line.length === 0) {
        return;
      }

      const workshopType = {} as WorkshopType;
      workshopType.name = line[0];

      if (line.length > 1) {
        switch (line[1]) {
          case 'ET':
            workshopType.links = LinkType.AND;
            break;
          default:
            workshopType.links = LinkType.OR;
            break;
        }
      } else {
        workshopType.links = LinkType.OR;
      }

      workshopTypes.push(workshopType);
    });

    await dispatch(importMultipleWorkshopTypes(workshopTypes));

    setCurrentWorkshopType({} as WorkshopType);
  }, []);

  const initNewWorkshop = useCallback(() => {
    workshopNameInputRef.current?.focus();
    setCurrentWorkshop({} as WorkshopDetailed);
  }, [workshopNameInputRef]);

  const saveCurrentWorkshop = useCallback(async () => {
    if (!currentWorkshop.type?.id) {
      dispatch(setError('Atelier invalide'));
      return;
    }

    if (
      !currentWorkshop.startDate ||
      !currentWorkshop.endDate ||
      currentWorkshop.startDate >= currentWorkshop.endDate
    ) {
      dispatch(setError('Dates invalides'));
      return;
    }

    await dispatch(createOrUpdateWorkshop(currentWorkshop));
    setCurrentWorkshop({} as WorkshopDetailed);
  }, [currentWorkshop]);

  const deleteSelectedWorkshops = useCallback(async (ids: Array<number>) => {
    await dispatch(deleteWorkshops(ids));
    setCurrentWorkshop({} as WorkshopDetailed);
  }, []);

  const importWorkshops = useCallback(
    async (data: Array<Array<string>>) => {
      const workshops: Array<Workshop> = [];

      data.forEach((line) => {
        if (line.length === 0) {
          return;
        }

        const workshop = {} as Workshop;

        const workshopType = workshopTypes.find((type) => type.name === line[0]);
        if (!workshopType) {
          return;
        }

        workshop.workshop_type_id = workshopType.id;

        if (line.length > 1) {
          workshop.group = parseInt(line[1], 10);
        } else {
          workshop.group = 0;
        }

        if (line.length > 2) {
          const location = locations.find((location) => location.name === line[2]);
          if (location) {
            workshop.location = location;
          }
        }

        if (line.length > 3) {
          workshop.numSeats = parseInt(line[3], 10);
        } else {
          workshop.numSeats = 0;
        }

        if (line.length > 4) {
          const instructor = instructors.find(
            (instructor) => toFullName(instructor.firstname, instructor.lastname) === line[4],
          );
          if (instructor) {
            workshop.instructorsRegistered = [instructor];
          }
        }

        if (line.length > 5) {
          const startDate = moment(line[5], 'DD/MM/YYYY HH:mm');
          if (startDate.isValid()) {
            workshop.startDate = startDate.unix();
          }
        } else {
          workshop.startDate = moment().unix();
        }

        if (line.length > 6) {
          const endDate = moment(line[6], 'DD/MM/YYYY HH:mm');
          if (endDate.isValid()) {
            workshop.endDate = endDate.unix();
          }
        } else {
          workshop.endDate = moment().unix();
        }

        workshops.push(workshop);
      });

      await dispatch(importMultipleWorkshops(workshops));

      setCurrentWorkshop({} as WorkshopDetailed);
    },
    [locations, workshopTypes],
  );

  const initNewUser = useCallback(() => {
    userEmailInputRef.current?.focus();
    setCurrentUser({} as User);
  }, [userEmailInputRef]);

  const saveCurrentUser = useCallback(async () => {
    await dispatch(createOrUpdateUser(currentUser));
    setCurrentUser({} as User);
  }, [currentUser]);

  const deleteSelectedUsers = useCallback(async (ids: Array<number>) => {
    await dispatch(deleteUsers(ids));
    setCurrentUser({} as User);
  }, []);

  const importUsers = useCallback(
    async (data: Array<Array<string>>) => {
      const users: Array<UserBase> = [];

      data.forEach((line) => {
        if (line.length === 0) {
          return;
        }

        const user: UserBase = {
          email: line[0],
        } as UserBase;

        if (line.length > 1) {
          user.password = line[1];
        } else {
          user.password = '';
        }

        if (line.length > 2) {
          switch (line[2]) {
            case 'Administrateur':
              user.role = Role.ADMINISTRATOR;
              break;
            default:
              user.role = Role.COUNSELOR;
              break;
          }
        } else {
          user.role = Role.COUNSELOR;
        }

        if (line.length > 3) {
          const instructor = instructors.find(
            (instructor) => toFullName(instructor.firstname, instructor.lastname) === line[3],
          );
          if (instructor) {
            user.instructor = instructor;
          }
        }

        users.push(user);
      });

      await dispatch(importMultipleUsers(users));

      setCurrentUser({} as User);
    },
    [instructors],
  );

  return (
    <React.Fragment>
      <Dialog className={classes.warningDialog} open={warningDialogOpened} onClose={closeWarningDialog}>
        <DialogTitle>Attention - Zone d&apos;administration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Attention, cette page d&apos;administration permet de modifier l&apos;intégralité des données, sans
            restriction.
            <br />
            Assurez-vous de savoir ce que vous faites en arrivant sur cette page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarningDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <TabsLayout className={classes.root} tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}>
        {/*Youth*/}
        <AdminPanel
          selected={currentYoung}
          setSelected={onYoungClicked}
          values={youthByFirstLetter}
          valuesTextFn={computeYoungText}
          onNewClicked={initNewYoung}
          onSaveClicked={saveCurrentYoung}
          onDeleteClicked={deleteSelectedYouth}
          onImport={importYouth}
          filters={{
            filtersApplied:
              youthFilters.name !== '' ||
              youthFilters.group > 0 ||
              youthFilters.counselorId !== 0 ||
              youthFilters.registrations != RegistrationsFilterValue.ALL,
            onResetFilters: () => dispatch(resetYouthFilters()),
            textFilter: {
              name: 'Nom',
              value: youthFilters.name,
              onChange: (value) => dispatch(setYoungNameFilter(value)),
            },
            selectFilters: [
              {
                name: 'Cohorte',
                options: [
                  { label: 'Tous', value: 0 },
                  ...youthGroups.map((group) => ({ label: group.toString(10), value: group })),
                ],
                value: youthFilters.group,
                onChange: (value) => dispatch(setYoungGroupFilter(value as number)),
              },
              {
                name: 'Conseiller',
                options: [
                  { label: 'Tous', value: 0 },
                  { label: 'Pas de conseiller', value: -1 },
                  ...youthCounselors.map((counselor) => ({
                    label: toFullName(counselor.firstname, counselor.lastname),
                    value: counselor.id,
                  })),
                ],
                value: youthFilters.counselorId,
                onChange: (value) => dispatch(setYoungCounselorFilter(value as number)),
              },
            ],
          }}
          fields={[
            {
              label: 'Nom de famille',
              getter: (young: YoungDetailed) => young.lastname || '',
              setter: (value: string) => setCurrentYoung({ ...currentYoung, lastname: value }),
              containerProps: { xs: 12, sm: 6 },
              inputRef: youngLastnameInputRef,
            },
            {
              label: 'Prénom(s)',
              getter: (young: YoungDetailed) => young.firstname || '',
              setter: (value: string) => setCurrentYoung({ ...currentYoung, firstname: value }),
              containerProps: { xs: 12, sm: 6 },
            },
            {
              label: 'Cohorte',
              getter: (young: YoungDetailed) => (isNaN(young.group) ? '' : young.group),
              setter: (value: string) => setCurrentYoung({ ...currentYoung, group: parseInt(value, 10) }),
              containerProps: { xs: 12, sm: 6 },
            },
            {
              label: 'Conseiller/ère',
              type: 'select',
              options: [
                { label: '-', value: 0 },
                ...instructors.map((instructor) => ({
                  label: toFullName(instructor.firstname, instructor.lastname),
                  value: instructor.id,
                })),
              ],
              getter: (young: YoungDetailed) => young.counselor?.id || '',
              setter: (value: string) => {
                const counselorId = parseInt(value, 10);
                setCurrentYoung({
                  ...currentYoung,
                  counselor: { id: counselorId > 0 ? counselorId : null } as Instructor,
                });
              },
              containerProps: { xs: 12, sm: 6 },
            },
          ]}
        />

        {/*Instructors*/}
        <AdminPanel
          selected={currentInstructor}
          setSelected={onInstructorClicked}
          values={instructorsByFirstLetter}
          valuesTextFn={computeInstructorText}
          onNewClicked={initNewInstructor}
          onSaveClicked={saveCurrentInstructor}
          onDeleteClicked={deleteSelectedInstructors}
          onImport={importInstructors}
          filters={{
            filtersApplied:
              instructorsFilters.name !== '' || instructorsFilters.registrations !== RegistrationsFilterValue.ALL,
            onResetFilters: () => dispatch(resetInstructorsFilters()),
            textFilter: {
              name: 'Nom',
              value: instructorsFilters.name,
              onChange: (value) => dispatch(setInstructorNameFilter(value)),
            },
          }}
          fields={[
            {
              label: 'Nom de famille',
              getter: (instructor: Instructor) => instructor.lastname || '',
              setter: (value: string) => setCurrentInstructor({ ...currentInstructor, lastname: value }),
              containerProps: { xs: 12, sm: 6 },
              inputRef: instructorLastnameInputRef,
            },
            {
              label: 'Prénom(s)',
              getter: (instructor: Instructor) => instructor.firstname || '',
              setter: (value: string) => setCurrentInstructor({ ...currentInstructor, firstname: value }),
              containerProps: { xs: 12, sm: 6 },
            },
          ]}
        />

        {/*Locations*/}
        <AdminPanel
          selected={currentLocation}
          setSelected={onLocationClicked}
          values={locationsByFirstLetter}
          valuesTextFn={computeLocationText}
          onNewClicked={initNewLocation}
          onSaveClicked={saveCurrentLocation}
          onDeleteClicked={deleteSelectedLocations}
          onImport={importLocations}
          fields={[
            {
              label: 'Nom',
              getter: (location: Location) => location.name || '',
              setter: (value: string) => setCurrentLocation({ ...currentLocation, name: value }),
              containerProps: { xs: 12, sm: 6 },
              inputRef: locationNameInputRef,
            },
          ]}
        />

        {/*Workshops Types*/}
        <AdminPanel
          selected={currentWorkshopType}
          setSelected={onWorkshopTypeClicked}
          values={workshopTypesByFirstLetter}
          valuesTextFn={computeWorkshopTypeText}
          onNewClicked={initNewWorkshopType}
          onSaveClicked={saveCurrentWorkshopType}
          onDeleteClicked={deleteSelectedWorkshopTypes}
          onImport={importWorkshopTypes}
          fields={[
            {
              label: 'Nom',
              getter: (workshopType: WorkshopType) => workshopType.name || '',
              setter: (value: string) => setCurrentWorkshopType({ ...currentWorkshopType, name: value }),
              containerProps: { xs: 12, sm: 6 },
              inputRef: workshopTypeNameInputRef,
            },
            {
              label: 'Lien',
              type: 'select',
              getter: (workshopType: WorkshopType) => workshopType.links || LinkType.OR,
              setter: (value: string) => setCurrentWorkshopType({ ...currentWorkshopType, links: value as LinkType }),
              containerProps: { xs: 12, sm: 6 },
              options: [
                { label: 'OU', value: LinkType.OR },
                { label: 'ET', value: LinkType.AND },
              ],
            },
          ]}
        />

        {/*Workshops*/}
        <AdminPanel
          selected={currentWorkshop}
          setSelected={onWorkshopClicked}
          values={workshopsByFirstLetter}
          valuesTextFn={computeWorkshopText}
          valuesSubTextFn={computeWorkshopSubText}
          onNewClicked={initNewWorkshop}
          onSaveClicked={saveCurrentWorkshop}
          onDeleteClicked={deleteSelectedWorkshops}
          fields={[
            {
              label: 'Type',
              type: 'select',
              getter: (workshop: WorkshopDetailed) => workshop.type?.id || '',
              setter: (value: string) =>
                setCurrentWorkshop({ ...currentWorkshop, type: { ...currentWorkshop.type, id: parseInt(value, 10) } }),
              containerProps: { xs: 12, sm: 12 },
              inputRef: workshopNameInputRef,
              options: workshopTypes.map((workshopType) => ({ label: workshopType.name, value: workshopType.id })),
            },
            {
              label: 'Cohorte',
              getter: (workshop: WorkshopDetailed) => (isNaN(workshop.group) ? '' : workshop.group),
              setter: (value: string) => setCurrentWorkshop({ ...currentWorkshop, group: parseInt(value, 10) }),
              containerProps: { xs: 12, sm: 6 },
            },
            {
              label: 'Lieu',
              type: 'select',
              getter: (workshop: WorkshopDetailed) => workshop.location?.id || '',
              setter: (value: string) => {
                const newWorkshop = { ...currentWorkshop };
                const location = locations.find((location) => location.id === parseInt(value, 10));
                if (location) {
                  newWorkshop.location = location;
                }
                setCurrentWorkshop(newWorkshop);
              },
              containerProps: { xs: 12, sm: 6 },
              options: locations.map((location) => ({ label: location.name, value: location.id })),
            },
            {
              label: 'Nombre de places',
              getter: (workshop: WorkshopDetailed) => (isNaN(workshop.numSeats) ? '' : workshop.numSeats),
              setter: (value: string) => setCurrentWorkshop({ ...currentWorkshop, numSeats: parseInt(value, 10) }),
              containerProps: { xs: 12, sm: 6 },
            },
            {
              label: 'Intervenant',
              type: 'select',
              getter: (workshop: WorkshopDetailed) =>
                workshop.instructorsRegistered?.length > 0 ? workshop.instructorsRegistered[0].id : '',
              setter: (value: string) => {
                const newWorkshop = { ...currentWorkshop, instructorsRegistered: [] as Array<Instructor> };
                const instructor = instructors.find((instructor) => instructor.id === parseInt(value, 10));
                if (instructor) {
                  newWorkshop.instructorsRegistered.push(instructor);
                }
                setCurrentWorkshop(newWorkshop);
              },
              containerProps: { xs: 12, sm: 6 },
              options: [{ label: '-', value: 0 }].concat(
                instructors.map((instructor) => ({
                  label: toFullName(instructor.firstname, instructor.lastname),
                  value: instructor.id,
                })),
              ),
            },
            {
              label: 'Date de début',
              type: 'datetime',
              getter: (workshop: WorkshopDetailed) =>
                workshop.startDate ? moment.unix(workshop.startDate).format(moment.HTML5_FMT.DATETIME_LOCAL) : '',
              setter: (value: string) => {
                if (!value) {
                  return;
                }
                setCurrentWorkshop({
                  ...currentWorkshop,
                  startDate: moment(value, moment.HTML5_FMT.DATETIME_LOCAL).unix(),
                });
              },
              containerProps: { xs: 12, sm: 6 },
            },
            {
              label: 'Date de fin',
              type: 'datetime',
              getter: (workshop: WorkshopDetailed) =>
                workshop.endDate ? moment.unix(workshop.endDate).format(moment.HTML5_FMT.DATETIME_LOCAL) : '',
              setter: (value: string) => {
                if (!value) {
                  return;
                }
                setCurrentWorkshop({
                  ...currentWorkshop,
                  endDate: moment(value, moment.HTML5_FMT.DATETIME_LOCAL).unix(),
                });
              },
              containerProps: { xs: 12, sm: 6 },
            },
          ]}
          onImport={importWorkshops}
          filters={{
            filtersApplied:
              workshopsFilters.name !== '' ||
              workshopsFilters.typeId !== 0 ||
              workshopsFilters.locationId !== 0 ||
              workshopsFilters.group > 0 ||
              workshopsFilters.youthRegistrations !== RegistrationsFilterValue.ALL ||
              workshopsFilters.instructorId !== 0,
            onResetFilters: () => dispatch(resetWorkshopsFilters()),
            textFilter: {
              name: 'Nom',
              value: workshopsFilters.name,
              onChange: (value) => dispatch(setWorkshopNameFilter(value)),
            },
            selectFilters: [
              {
                name: 'Type',
                options: [
                  { label: 'Tous', value: 0 },
                  ...workshopTypes.map((workshopType) => ({ label: workshopType.name, value: workshopType.id })),
                ],
                value: workshopsFilters.typeId,
                onChange: (value) => dispatch(setWorkshopTypeFilter(value as number)),
              },
              {
                name: 'Lieu',
                options: [
                  { label: 'Tous', value: 0 },
                  { label: 'Lieu inconnu', value: -1 },
                  ...locations.map((location) => ({ label: location.name, value: location.id })),
                ],
                value: workshopsFilters.locationId,
                onChange: (value) => dispatch(setWorkshopLocationFilter(value as number)),
              },
              {
                name: 'Cohorte',
                options: [
                  { label: 'Tous', value: 0 },
                  ...workshopsGroups.map((group) => ({ label: group.toString(10), value: group })),
                ],
                value: workshopsFilters.group,
                onChange: (value) => dispatch(setWorkshopGroupFilter(value as number)),
              },
            ],
          }}
        />

        {/*Users*/}
        <AdminPanel
          selected={currentUser}
          setSelected={onUserClicked}
          values={usersByFirstLetter}
          valuesTextFn={computeUserText}
          onNewClicked={initNewUser}
          onSaveClicked={saveCurrentUser}
          onDeleteClicked={deleteSelectedUsers}
          onImport={importUsers}
          fields={[
            {
              label: 'Email',
              getter: (user: User) => user.email || '',
              setter: (value: string) => setCurrentUser({ ...currentUser, email: value }),
              containerProps: { xs: 12, sm: 6 },
              inputRef: userEmailInputRef,
            },
            {
              label: 'Mot de passe',
              getter: (user: User) => user.password || '',
              setter: (value: string) => setCurrentUser({ ...currentUser, password: value }),
              containerProps: { xs: 12, sm: 6 },
            },
            {
              label: 'Role',
              type: 'select',
              getter: (user: User) => user.role || Role.COUNSELOR,
              setter: (value: string) => setCurrentUser({ ...currentUser, role: value as Role }),
              containerProps: { xs: 12, sm: 6 },
              options: [
                { label: 'Conseiller', value: Role.COUNSELOR },
                { label: 'Administrateur', value: Role.ADMINISTRATOR },
              ],
            },
            {
              label: 'Intervenant/e',
              type: 'select',
              options: [
                { label: '-', value: 0 },
                ...instructors.map((instructor) => ({
                  label: toFullName(instructor.firstname, instructor.lastname),
                  value: instructor.id,
                })),
              ],
              getter: (user: User) => user.instructor?.id || '',
              setter: (value: string) => {
                const instructorId = parseInt(value, 10);
                setCurrentUser({
                  ...currentUser,
                  instructor: { id: instructorId > 0 ? instructorId : null } as Instructor,
                });
              },
              containerProps: { xs: 12, sm: 6 },
            },
          ]}
        />
      </TabsLayout>
    </React.Fragment>
  );
};

export default Admin;
