import React, { useCallback } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';
import { SelectOption } from '../@types/data';

const useStyles = makeStyles()(() => ({
  title: {
    minHeight: '30px !important',
  },
  titleContent: {
    margin: '5px 0 !important',
  },
  titleIcon: {
    padding: '3px 12px !important',
  },
  titleText: {
    lineHeight: '30px',
  },
}));

interface TextFilter {
  name: string;
  value: string;
  onChange: (value: string) => void;
}

interface SelectFilter {
  name: string;
  options: Array<SelectOption>;
  value: number | string;
  onChange: (value: number | string) => void;
}

type AccordionFiltersProps = {
  textFilter?: TextFilter;
  selectFilters?: Array<SelectFilter>;
  filtersApplied?: boolean;
  onResetFilters?: () => void;
};
const AccordionFilters: React.FC<AccordionFiltersProps> = (props: AccordionFiltersProps) => {
  const { textFilter, selectFilters, filtersApplied, onResetFilters } = props;

  const { classes } = useStyles();

  const onClearFiltersClicked = useCallback(
    (event) => {
      if (onResetFilters) {
        onResetFilters();
      }

      event.stopPropagation();
    },
    [onResetFilters],
  );

  return (
    <Accordion>
      <AccordionSummary
        className={classes.title}
        classes={{ content: classes.titleContent, expandIconWrapper: classes.titleIcon }}
        expandIcon={<ExpandMoreIcon />}
      >
        {filtersApplied && (
          <IconButton size="small" edge="start" onClick={onClearFiltersClicked}>
            <ClearIcon />
          </IconButton>
        )}
        <Typography variant="body2" className={classes.titleText}>
          Filtres
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {textFilter && (
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel htmlFor={`${textFilter.name.toLowerCase()}-text-filter-input`} shrink>
                  {textFilter.name}
                </InputLabel>
                <Input
                  id={`${textFilter.name.toLowerCase()}-text-filter-input`}
                  value={textFilter.value}
                  onChange={(event) => textFilter.onChange(event.target.value)}
                  type="text"
                  fullWidth
                />
              </FormControl>
            </Grid>
          )}

          {selectFilters &&
            selectFilters.map((selectFilter, index) => (
              <Grid key={index} item xs={12}>
                <FormControl fullWidth size="small">
                  <InputLabel id={`${selectFilter.name.toLowerCase()}-select-filter-label`}>
                    {selectFilter.name}
                  </InputLabel>
                  <Select
                    labelId={`${selectFilter.name.toLowerCase()}-select-filter-label`}
                    label={selectFilter.name}
                    value={selectFilter.value}
                    onChange={(event) => selectFilter.onChange(event.target.value as number)}
                  >
                    {selectFilter.options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionFilters;
