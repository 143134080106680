import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalConfigSchema } from '../../@types/data';
import { load, setError, setLoading, setSuccess } from '../globalSlice';
import { AppThunk } from '../../store';
import { Role } from '../../@types/enums';

interface ConfigState {
  current: LocalConfigSchema;
}

const initialState: ConfigState = {
  current: {
    server: {
      address: '',
    },
    auth: {
      id: 0,
      token: '',
      role: Role.COUNSELOR,
    },
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<LocalConfigSchema>) {
      state.current = action.payload;
    },
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;

export const updateConfig =
  (newConfig: LocalConfigSchema): AppThunk =>
  async (dispatch) => {
    await (window as any).electron.config.setLocalConfig(newConfig);
    dispatch(setConfig(newConfig));
  };

export const loadConfig = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const electron = (window as any).electron;
    if (electron) {
      const config: LocalConfigSchema = await (window as any).electron.config.getLocalConfig();
      dispatch(setConfig(config));
    } else {
      console.info('Not loading config as running in browser');
    }
  } catch (err: any) {
    dispatch(setError('Impossible de charger la configuration locale'));
    console.error(err);
  }

  dispatch(setLoading(false));
};

export const saveNewConfig =
  (newConfig: LocalConfigSchema, reloadData: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));

    try {
      await dispatch(updateConfig(newConfig));
    } catch (err: any) {
      dispatch(setError('Impossible de sauvegarder la configuration locale'));
      console.error(err);

      dispatch(setLoading(false));
      return;
    }

    if (reloadData) {
      try {
        await dispatch(load('admin'));
      } catch (err: any) {
        dispatch(setError('Impossible de charger les données en utilisant la nouvelle configuration'));
        console.error(err);

        dispatch(setLoading(false));
        return;
      }
    }

    dispatch(setSuccess('Configuration enregistrée avec succès'));
    dispatch(setLoading(false));
  };
