import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Admin from '../pages/Admin';
import Config from '../pages/Config';
import Youth from '../pages/Youth';
import Workshops from '../pages/Workshops';
import { update, LoadableResources } from '../features/globalSlice';
import TopBar from './TopBar';
import SideMenu from './SideMenu';
import Instructors from '../pages/Instructors';
import { isAdmin } from '../features/users/usersSelector';

const useStyles = makeStyles()((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(theme.window.padding),
  },
}));

const MainPanel: React.FC = () => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const signedAsAdmin = useSelector(isAdmin);

  useEffect(() => {
    if (['/', '/config', '/login'].includes(pathname)) {
      return;
    }

    const root = pathname.slice('/app'.length).split('/')[1];
    dispatch(update(root as LoadableResources, signedAsAdmin));
  }, [pathname]);

  return (
    <React.Fragment>
      <TopBar showButtons />
      <SideMenu />
      <Container component="main" className={classes.content}>
        <Toolbar />
        <Routes>
          <Route path="youth">
            <Route path=":youngId" element={<Youth />} />
            <Route path="" element={<Youth />} />
          </Route>
          <Route path="instructors">
            <Route path=":instructorId" element={<Instructors />} />
            <Route path="" element={<Instructors />} />
          </Route>
          <Route path="workshops">
            <Route path=":workshopId" element={<Workshops />} />
            <Route path="" element={<Workshops />} />
          </Route>
          <Route path="admin" element={<Admin />} />
          <Route path="config" element={<Config />} />
          <Route path="*" element={<Navigate to="youth" />} />
        </Routes>
      </Container>
    </React.Fragment>
  );
};

export default MainPanel;
