import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Availability,
  Instructor,
  InstructorDetailed,
  InstructorsFilters,
  Location,
  WorkshopType,
} from '../../@types/data';
import { AppThunk } from '../../store';
import api from '../../services/api';
import { load, setError, setLoading, setSuccess } from '../globalSlice';
import { getInstructors, getSelectedInstructor } from './instructorsSelectors';
import { deleteCounselorsFromYouth, updateCounselorsFromYouth } from '../youth/youthSlice';
import { deleteInstructorsFromUsers } from '../users/usersSlice';
import { getSelectedWorkshop } from '../workshops/workshopsSelectors';
import { deleteInstructorsFromWorkshops } from '../workshops/workshopsSlice';
import { RegistrationsFilterValue } from '../../@types/enums';
import { checkTime } from '../../utils/utils';

interface InstructorsState {
  selectedId: number | null;
  list: Array<InstructorDetailed>;
  filters: InstructorsFilters;
}

const initialFilters: InstructorsFilters = {
  name: '',
  registrations: RegistrationsFilterValue.ALL,
};

const initialState: InstructorsState = {
  selectedId: null,
  list: [],
  filters: initialFilters,
};

const instructorsSlice = createSlice({
  name: 'instructors',
  initialState,
  reducers: {
    setInstructorsList(state, action: PayloadAction<Array<InstructorDetailed>>) {
      state.list = action.payload;
    },
    selectInstructor(state, action: PayloadAction<number>) {
      state.selectedId = action.payload;
    },
    setLocalInstructor(state, action: PayloadAction<InstructorDetailed>) {
      const index = state.list.findIndex((instructor) => instructor.id === action.payload.id);
      state.list.splice(index, 1, action.payload);
    },
    setInstructorNameFilter(state, action: PayloadAction<string>) {
      state.filters.name = action.payload;
    },
    setInstructorRegistrationsFilter(state, action: PayloadAction<RegistrationsFilterValue>) {
      state.filters.registrations = action.payload;
    },
    resetInstructorsFilters(state) {
      state.filters = initialFilters;
    },
  },
});

export const {
  setInstructorsList,
  selectInstructor,
  setLocalInstructor,
  setInstructorNameFilter,
  setInstructorRegistrationsFilter,
  resetInstructorsFilters,
} = instructorsSlice.actions;

export default instructorsSlice.reducer;

export const syncInstructors = (): AppThunk => async (dispatch) => {
  const instructors: Array<InstructorDetailed> = await api.getInstructors();

  dispatch(setInstructorsList(instructors));
};

export const createOrUpdateInstructor =
  (instructor: Instructor): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (instructor.id !== undefined) {
        await api.updateInstructor(instructor);
        await dispatch(updateCounselorsFromYouth(instructor));
      } else {
        await api.insertInstructors([instructor]);
      }

      await dispatch(syncInstructors());

      await dispatch(setSuccess('Intervenant(e) enregistré(e) avec succès'));
    } catch (err: any) {
      console.error('Error while inserting or updating instructor:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const importMultipleInstructors =
  (instructors: Array<Instructor>): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (instructors.length === 0) {
        throw 'Aucun intervenant à importer';
      }

      await api.insertInstructors(instructors);

      await dispatch(syncInstructors());

      await dispatch(setSuccess('Intervenants importés avec succès'));
    } catch (err: any) {
      console.error('Error while importing instructors', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const deleteInstructors =
  (instructorsIds: Array<number>): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteInstructorsByIds(instructorsIds);

      await dispatch(syncInstructors());

      await dispatch(deleteInstructorsFromUsers(instructorsIds));
      await dispatch(deleteCounselorsFromYouth(instructorsIds));
      await dispatch(deleteInstructorsFromWorkshops(instructorsIds));

      await dispatch(setSuccess('Intervenant(s) supprimé(s) avec succès'));
    } catch (err: any) {
      console.error('Error while deleting instructors:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const updateSelectedInstructorWorkshopTypesWishes =
  (workshopTypes: Array<WorkshopType>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedInstructor = getSelectedInstructor(getState());

      if (!selectedInstructor) {
        dispatch(setLoading(false));
        return;
      }

      await api.updateInstructorWorkshopTypesWishes(selectedInstructor.id, workshopTypes);

      await dispatch(syncInstructors());
    } catch (err: any) {
      console.error('Error while updating instructor workshop types wishes:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const updateSelectedInstructorLocationWishes =
  (locations: Array<Location>): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedInstructor = getSelectedInstructor(getState());

      if (!selectedInstructor) {
        dispatch(setLoading(false));
        return;
      }

      await api.updateInstructorLocationsWishes(selectedInstructor.id, locations);

      await dispatch(syncInstructors());
    } catch (err: any) {
      console.error('Error while updating instructor locations wishes:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const registerInstructorToSelectedWorkshop =
  (instructor: InstructorDetailed): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedWorkshop = getSelectedWorkshop(getState());

      if (!selectedWorkshop) {
        throw 'Aucun atelier sélectionné';
      }

      const newWorkshopsIds = instructor.registrations.map((w) => w.id).concat(selectedWorkshop.id);
      await api.updateInstructorRegistrations(instructor.id, newWorkshopsIds);

      await dispatch(load('instructors-manual-registration'));
    } catch (err: any) {
      console.error('Error while adding instructor registration:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const unregisterInstructorFromSelectedWorkshop =
  (instructor: Instructor): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedWorkshop = getSelectedWorkshop(getState());

      if (!selectedWorkshop) {
        dispatch(setLoading(false));
        return;
      }

      const fullInstructor = getInstructors(getState()).find((i) => i.id === instructor.id);
      if (!fullInstructor) {
        throw "Impossible de trouver l'intervenant à supprimer de la list";
      }

      const newWorkshopsIds = fullInstructor.registrations
        .filter((workshop) => workshop.id !== selectedWorkshop.id)
        .map((workshop) => workshop.id);
      await api.updateInstructorRegistrations(fullInstructor.id, newWorkshopsIds);

      await dispatch(load('instructors-manual-registration'));
    } catch (err: any) {
      console.error('Error while removing instructor registration:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const addAvailabilityToSelectedInstructor =
  (availability: Availability): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      const selectedInstructor = getSelectedInstructor(getState());

      if (!selectedInstructor) {
        dispatch(setLoading(false));
        return;
      }

      if (!checkTime([availability], selectedInstructor.registrations)) {
        throw 'Cet intervenant anime un atelier ayant lieu pendant cette indisponibilité.';
      }

      availability.personId = selectedInstructor.id;

      await api.addInstructorAvailability(availability);

      await dispatch(syncInstructors());
    } catch (err: any) {
      console.error('Error while adding availability to instructor:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const removeInstructorAvailability =
  (availabilityId: number): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteInstructorAvailability(availabilityId);

      await dispatch(syncInstructors());
    } catch (err: any) {
      console.error('Error while removing availability from instructor:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const generatePlanningsForSelectedInstructor = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    const selectedInstructor = getSelectedInstructor(getState());

    if (!selectedInstructor) {
      dispatch(setLoading(false));
      return;
    }

    await (window as any).electron.pdfGenerator.generatePlannings(selectedInstructor);
  } catch (err: any) {
    console.error('Error while generating instructor plannings:', err);
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};

export const generateGlobalInstructorsPlanning =
  (progressCallback: (value: number) => void): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await dispatch(syncInstructors());
      const instructors = getInstructors(getState());
      if (instructors.length > 0) {
        await (window as any).electron.pdfGenerator.generateGlobalInstructorsPlanning(instructors, progressCallback);
      }
    } catch (err: any) {
      console.error('Error while generating global instructors plannings:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };
