import { RootState } from '../../store/rootReducer';
import { GroupedIdentifiedItems, Location } from '../../@types/data';
import { createSelector } from '@reduxjs/toolkit';

export const getLocations = (state: RootState): Array<Location> => state.locations.list;

export const getLocationsByFirstLetter = createSelector(getLocations, (locations): GroupedIdentifiedItems => {
  return locations.reduce((result, location) => {
    const firstLetter = location.name.charAt(0).toUpperCase();

    if (!result[firstLetter]) {
      result[firstLetter] = [];
    }

    result[firstLetter].push(location);

    return result;
  }, {});
});
