import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { setError, setLoading, setSuccess } from '../globalSlice';
import { AppThunk } from '../../store';
import { getCurrentConfig } from '../config/configSelector';
import { updateConfig } from '../config/configSlice';
import api from '../../services/api';
import { User, UserAuth, UserBase } from '../../@types/data';
import { Role } from '../../@types/enums';
import { getCurrentUser } from './usersSelector';

interface UsersState {
  currentUser: UserAuth | null;
  list: Array<User>;
}

const initialState: UsersState = {
  currentUser: null,
  list: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<UserAuth | null>) {
      state.currentUser = action.payload;
    },
    setUsers(state, action: PayloadAction<Array<User>>) {
      state.list = action.payload;
    },
    deleteInstructorsFromUsers(state, action: PayloadAction<Array<number>>) {
      state.list = state.list.map((user) => {
        if (!user.instructor || !action.payload.includes(user.instructor.id)) {
          return user;
        }

        return {
          ...user,
          instructor: null,
        };
      });
    },
  },
});

export const { deleteInstructorsFromUsers, setCurrentUser, setUsers } = usersSlice.actions;

export default usersSlice.reducer;

export const loadUserFromConfig = (): AppThunk => (dispatch, getState) => {
  try {
    const currentConfig = getCurrentConfig(getState());

    dispatch(
      setCurrentUser({
        id: currentConfig.auth.id,
        token: currentConfig.auth.token,
        role: currentConfig.auth.role,
      }),
    );
  } catch (err: any) {
    dispatch(setError(err.toString()));
    console.error(err);
  }
};

export const loadUsers = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setError(null));

  try {
    dispatch(syncUsers());
  } catch (err: any) {
    console.error('Error while updating users:', err);
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};

export const syncUsers = (): AppThunk => async (dispatch) => {
  const users = await api.getUsers();
  dispatch(setUsers(users));
};

export const createOrUpdateUser =
  (user: User): AppThunk =>
  async (dispatch, getState) => {
    let currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à créer ou modifer un utilisateur"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      if (user.id !== undefined) {
        await api.updateUser(user);

        // If current user role has changed, set it immediately
        if (currentUser && user.id === currentUser.id) {
          currentUser = produce(currentUser, (draftState) => {
            draftState.role = user.role;
          });
          dispatch(setCurrentUser(currentUser));
        }
      } else {
        await api.insertUsers([user]);
      }

      await dispatch(syncUsers());

      await dispatch(setSuccess('Utilisateur enregistré avec succès'));
    } catch (err: any) {
      console.error('Error while inserting or updating user:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const importMultipleUsers =
  (users: Array<UserBase>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à importer des utilisateurs"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.insertUsers(users);
      await dispatch(syncUsers());

      await dispatch(setSuccess('Utilisateurs importés avec succès'));
    } catch (err: any) {
      console.error('Error while importing users', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const deleteUsers =
  (usersIds: Array<number>): AppThunk =>
  async (dispatch, getState) => {
    const currentUser = getCurrentUser(getState());
    if (currentUser?.role !== Role.ADMINISTRATOR) {
      dispatch(setError("Vous n'êtes pas autorisé(e) à supprimer des utilisateurs"));
      return;
    }

    dispatch(setLoading(true));
    dispatch(setError(null));

    try {
      await api.deleteUsersByIds(usersIds);

      if (currentUser && usersIds.includes(currentUser.id)) {
        await dispatch(logoutUser());
      } else {
        await dispatch(syncUsers());
      }

      await dispatch(setSuccess('Utilisateur(s) supprimé(s) avec succès'));
    } catch (err: any) {
      console.error('Error while deleting users:', err);
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };

export const loginUser =
  (email: string, password: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
      const userAuth = await api.login(email, password);

      const currentConfig = getCurrentConfig(getState());
      const newConfig = produce(currentConfig, (draftState) => {
        draftState.auth.id = userAuth.id;
        draftState.auth.token = userAuth.token;
        draftState.auth.role = userAuth.role;
      });
      await dispatch(updateConfig(newConfig));

      // This will trigger a page change
      dispatch(setCurrentUser(userAuth));

      dispatch(setSuccess('Connecté avec succès'));
    } catch (err: any) {
      dispatch(setError(err.toString()));
      console.error(err);

      // Do not set loading to false if success because page changing will trigger update (another loading)
      dispatch(setLoading(false));
    }
  };

export const logoutUser = (): AppThunk => async (dispatch, getState) => {
  dispatch(setLoading(true));

  try {
    const currentConfig = getCurrentConfig(getState());
    const newConfig = produce(currentConfig, (draftState) => {
      draftState.auth.id = 0;
      draftState.auth.token = '';
      draftState.auth.role = Role.COUNSELOR;
    });
    await dispatch(updateConfig(newConfig));

    dispatch(setCurrentUser(null));

    dispatch(setSuccess('Déconnecté avec succès'));
  } catch (err: any) {
    dispatch(setError(err.toString()));
  }

  dispatch(setLoading(false));
};

export const changePassword =
  (newPassword: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));

    try {
      const currentConfig = getCurrentConfig(getState());

      await api.updateUser({
        id: currentConfig.auth.id,
        password: newPassword,
      } as User);
    } catch (err: any) {
      dispatch(setError(err.toString()));
    }

    dispatch(setLoading(false));
  };
