import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store/rootReducer';
import {
  IdentifiedItem,
  Instructor,
  LocationsIdsWishByPersonId,
  OrderableIdentifiedItems,
  TimeslotsById,
  WorkshopTypesWishRangeByPersonId,
  YoungDetailed,
  YouthByFirstLetter,
  YouthFilters,
} from '../../@types/data';
import {
  getSelectedWorkshop,
  getConstrainedWorkshopsTimeslots,
  getWorkshopTypes,
} from '../workshops/workshopsSelectors';
import { getLocations } from '../locations/locationsSelectors';
import { checkTime, toFullName } from '../../utils/utils';
import { LinkType, RegistrationsFilterValue } from '../../@types/enums';

export const getYouth = (state: RootState): Array<YoungDetailed> => state.youth.list;
export const getSelectedYoungId = (state: RootState): number | null => state.youth.selectedId;
export const getYouthFilters = (state: RootState): YouthFilters => state.youth.filters;

export const getYouthGroups = createSelector(getYouth, (youth): Array<number> => {
  const groups = youth.reduce((result, young) => {
    if (young.group) {
      result.add(young.group);
    }
    return result;
  }, new Set<number>());

  return Array.from(groups).sort((a, b) => a - b);
});

export const getYouthCounselors = createSelector(getYouth, (youth): Array<Instructor> => {
  const counselors = youth.reduce((result, young) => {
    if (young.counselor && young.counselor.id) {
      result.set(young.counselor.id, young.counselor);
    }
    return result;
  }, new Map<number, Instructor>());

  return Array.from(counselors.values()).sort((a, b) => {
    const aFullName = toFullName(a.firstname, a.lastname);
    const bFullName = toFullName(b.firstname, b.lastname);

    return aFullName.localeCompare(bFullName, 'fr');
  });
});

export const getSelectedYoung = createSelector(
  getYouth,
  getSelectedYoungId,
  (youth, selectedYoungId): YoungDetailed | null => {
    return youth.find((young) => young.id === selectedYoungId) || null;
  },
);

export const getYouthFiltered = createSelector(getYouth, getYouthFilters, (youth, filters): Array<YoungDetailed> => {
  // No filter apply
  if (
    filters.name.length === 0 &&
    filters.registrations === RegistrationsFilterValue.ALL &&
    filters.group === 0 &&
    filters.counselorId === 0
  ) {
    return youth;
  }

  return youth.filter((young) => {
    // Filter on name
    try {
      const nameFilter = RegExp(filters.name, 'i').test(`${young.lastname} ${young.firstname}`);
      if (!nameFilter) {
        return false;
      }
    } catch (err) {
      return false;
    }

    // Filter on group
    if (filters.group !== 0 && young.group !== filters.group) {
      return false;
    }

    // Filter on counselor
    const counselorFilter =
      filters.counselorId === 0 || // All counselors
      (filters.counselorId === -1 && young.counselor?.id === null) || // Unknown counselor
      young.counselor?.id === filters.counselorId; // Matching location
    if (!counselorFilter) {
      return false;
    }

    // Filter on registrations
    let registrationsFilter;
    switch (filters.registrations) {
      case RegistrationsFilterValue.NO_WISH:
        registrationsFilter = young.workshopTypesWishes.length === 0;
        break;

      case RegistrationsFilterValue.NO_REGISTRATION:
        registrationsFilter = young.registrations.length === 0;
        break;

      case RegistrationsFilterValue.NOT_SATISFIED:
        registrationsFilter = young.workshopTypesWishes.some(
          (wish) =>
            young.registrations.find((registration) => registration.type.id === wish.workshopType.id) === undefined,
        );
        break;

      default:
        registrationsFilter = true;
        break;
    }

    return registrationsFilter;
  });
});

export const getYouthByFirstLetter = createSelector(getYouthFiltered, (youth): YouthByFirstLetter => {
  return youth.reduce((result, young) => {
    const firstLetter = young.lastname.charAt(0).toUpperCase();

    if (!result[firstLetter]) {
      result[firstLetter] = [];
    }

    result[firstLetter].push(young);

    return result;
  }, {});
});

export const getWorkshopTypesWishesForSelectedYoung = createSelector(
  getSelectedYoung,
  getWorkshopTypes,
  (young, workshopTypes): OrderableIdentifiedItems => {
    const result = {
      ranked: [] as Array<IdentifiedItem>,
      rejected: [] as Array<IdentifiedItem>,
    };

    if (!young) {
      return result;
    }

    result.ranked = [...young.workshopTypesWishes].sort((a, b) => a.rank - b.rank).map((wish) => wish.workshopType);

    const rankedIds = result.ranked.map((item) => item.id);
    result.rejected = workshopTypes
      .filter((workshopType) => !rankedIds.includes(workshopType.id))
      .sort((a, b) => a.name.localeCompare(b.name, 'fr'));

    return result;
  },
);

export const getLocationsWishesForSelectedYoung = createSelector(
  getSelectedYoung,
  getLocations,
  (young, locations): OrderableIdentifiedItems => {
    const result = {
      ranked: [] as Array<IdentifiedItem>,
      rejected: [] as Array<IdentifiedItem>,
    };

    if (!young) {
      return result;
    }

    result.ranked = [...young.locationsWishes].sort((a, b) => a.rank - b.rank).map((wish) => wish.location);

    const rankedIds = result.ranked.map((item) => item.id);
    result.rejected = locations
      .filter((location) => !rankedIds.includes(location.id))
      .sort((a, b) => a.name.localeCompare(b.name, 'fr'));

    return result;
  },
);

export const getYouthBusyTimeslots = createSelector(getYouth, (youth): TimeslotsById => {
  return youth.reduce((result, young) => {
    const busyRegistrations = young.registrations.map((workshop) => ({
      startDate: workshop.startDate,
      endDate: workshop.endDate,
    }));

    const busyAvailabilities = young.availabilities.map((availability) => ({
      startDate: availability.startDate,
      endDate: availability.endDate,
    }));

    result[young.id] = busyRegistrations.concat(busyAvailabilities);

    return result;
  }, {} as TimeslotsById);
});

export const getAvailableYouthForSelectedWorkshop = createSelector(
  getSelectedWorkshop,
  getConstrainedWorkshopsTimeslots,
  getYouth,
  getYouthBusyTimeslots,
  (selectedWorkshop, workshopsTimeslots, youth, youthBusyTimeslots): Array<YoungDetailed> => {
    if (!selectedWorkshop) {
      return [];
    }

    return youth.filter((young) => {
      // Filter if already registered in another workshop with the same type AND workshop type has an OR link
      if (selectedWorkshop.type.links === LinkType.OR) {
        const alreadyRegistered = young.registrations.find((workshop) => workshop.type.id === selectedWorkshop.type.id);
        if (alreadyRegistered) {
          return false;
        }
      }

      return checkTime(youthBusyTimeslots[young.id], workshopsTimeslots[selectedWorkshop.id]);
    });
  },
);

export const getOrderedWorkshopTypesWishesByYoungId = createSelector(getYouth, (youth) => {
  return youth.reduce((result, young): WorkshopTypesWishRangeByPersonId => {
    result[young.id] = {
      min: 0,
      max: young.workshopTypesWishes.length,
      wishes: [...young.workshopTypesWishes].sort((a, b) => a.rank - b.rank).map((wish) => wish.workshopType),
    };
    return result;
  }, {});
});

export const getLocationsIdsWishesByYoungId = createSelector(getYouth, (youth) => {
  return youth.reduce((result, young): LocationsIdsWishByPersonId => {
    result[young.id] = [...young.locationsWishes].reduce((obj, wish) => {
      obj[wish.location.id] = wish.rank;
      return obj;
    }, {});
    return result;
  }, {});
});
