import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from 'tss-react/mui';
import { getError, getSuccess, isLoading } from './features/globalSelectors';
import MainPanel from './containers/MainPanel';
import { loadConfig } from './features/config/configSlice';
import { loadUserFromConfig } from './features/users/usersSlice';
import { setError, setSuccess } from './features/globalSlice';
import { isSignedIn } from './features/users/usersSelector';
import ConnectionPanel from './containers/ConnectionPanel';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  overlay: {
    color: 'black',
    zIndex: theme.zIndex.modal + 1,
  },
}));

function RequireAuth({ children }: { children: JSX.Element }) {
  const signedIn = useSelector(isSignedIn);
  const location = useLocation();

  if (!signedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function RequireAnonymous({ children }: { children: JSX.Element }) {
  const signedIn = useSelector(isSignedIn);

  if (signedIn) {
    return <Navigate to="/app" replace />;
  }

  return children;
}

const App: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [ready, setReady] = useState(false);

  const error = useSelector(getError);
  const loading = useSelector(isLoading);
  const success = useSelector(getSuccess);

  useEffect(() => {
    dispatch(loadConfig()).finally(() => {
      dispatch(loadUserFromConfig());
      setReady(true);
    });
  }, []);

  const onCloseError = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setError(null));
  }, []);

  const onCloseSuccess = useCallback((event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSuccess(null));
  }, []);

  return (
    <div className={classes.root}>
      <Backdrop className={classes.overlay} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={error !== null}
        onClose={onCloseError}
      >
        <Alert onClose={onCloseError} elevation={6} variant="filled" severity="error">
          Une erreur est survenue <em>({error})</em>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={success !== null}
        onClose={onCloseSuccess}
        autoHideDuration={5000}
      >
        <Alert onClose={onCloseSuccess} elevation={6} variant="filled" severity="success">
          {success}
        </Alert>
      </Snackbar>
      {ready && (
        <HashRouter>
          <Routes>
            <Route
              path="/app/*"
              element={
                <RequireAuth>
                  <MainPanel />
                </RequireAuth>
              }
            />
            <Route
              path="*"
              element={
                <RequireAnonymous>
                  <ConnectionPanel />
                </RequireAnonymous>
              }
            />
          </Routes>
        </HashRouter>
      )}
    </div>
  );
};

export default App;
