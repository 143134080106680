import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { makeStyles } from 'tss-react/mui';
import { LoadableResources, update } from '../features/globalSlice';
import LogoMissionLocale from '../images/logo_mission_locale.jpg';
import { logoutUser } from '../features/users/usersSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAdmin } from '../features/users/usersSelector';

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    padding: '0 !important',
  },
  buttons: {
    '& > button': {
      margin: theme.spacing(1),
    },
  },
  logoContainer: {
    flexWrap: 'nowrap',
  },
  logo: {
    height: theme.mixins.toolbar.minHeight ? parseInt(theme.mixins.toolbar.minHeight.toString(), 10) - 10 : 48,
    margin: '5px 10px',
    borderRadius: 3,
  },
  appName: {
    marginLeft: 10,
    fontWeight: 'bold',
    lineHeight: `${theme.mixins.toolbar.minHeight}px`,
  },
  appSubName: {
    marginLeft: 10,
    fontWeight: 'normal',
    lineHeight: `${theme.mixins.toolbar.minHeight}px`,
  },
}));

type TopBarProps = {
  showButtons?: boolean;
  showText?: string;
};
const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {
  const { showButtons, showText } = props;
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const signedAsAdmin = useSelector(isAdmin);

  const refresh = useCallback(async () => {
    const root = pathname.slice('/app'.length).split('/')[1];
    await dispatch(update(root as LoadableResources, signedAsAdmin));
  }, [pathname, signedAsAdmin]);

  const logout = useCallback(async () => {
    await dispatch(logoutUser());
    navigate('/login');
  }, []);

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.toolbar}>
        <Grid container className={classes.logoContainer}>
          <img src={LogoMissionLocale} alt="Logo MLG" className={classes.logo} />
          <Typography className={classes.appName} variant="h6" noWrap>
            ODSCo
          </Typography>
          <Typography className={classes.appSubName} variant="overline" noWrap>
            Offre De Services Collectif
          </Typography>
        </Grid>

        {showText && (
          <Grid className={classes.buttons} container justifyContent="flex-end" wrap="nowrap">
            <Typography>{showText}</Typography>
          </Grid>
        )}

        {showButtons && (
          <Grid className={classes.buttons} container justifyContent="flex-end" wrap="nowrap">
            <Button onClick={refresh} startIcon={<RefreshIcon />} variant="contained" size="small">
              Rafraîchir
            </Button>
            <Button
              onClick={logout}
              startIcon={<PowerSettingsNewIcon />}
              variant="contained"
              color="secondary"
              size="small"
            >
              Déconnecter
            </Button>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
