import React from 'react';
import { Link as RouterLink, Navigate, Route, Routes } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import Config from '../pages/Config';
import SignIn from '../pages/SignIn';

const useStyles = makeStyles()((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(theme.window.padding),
  },
}));

const ConnectionPanel: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Routes>
      <Route path="login" element={<SignIn />} />
      <Route
        path="config"
        element={
          <Container component="main" className={classes.content}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Link component={RouterLink} to="login" variant="body2">
                  Revenir à la page de connexion
                </Link>
              </Grid>
              <Grid item>
                <Config />
              </Grid>
            </Grid>
          </Container>
        }
      />
      <Route path="*" element={<Navigate to="login" replace />} />
    </Routes>
  );
};

export default ConnectionPanel;
